import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  signal,
  effect,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { AppSettings } from "../../../../app.settings";
import { Settings } from "../../../../app.settings.model";
import { rotate } from "../../../../theme/utils/app-animation";
import { MenuService } from "../../../../theme/services/menu.service";
import { MatDrawerContent } from "@angular/material/sidenav";
import { SubscriptionsContainer } from "../../../../theme/utils/subscriptions-container";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { ToastrService } from "ngx-toastr";
import { ConfigService } from "src/app/shared/services/config/config.service";
import { Platform } from "@ionic/angular";
import { ScanService } from "src/app/features/scan/services/scan.service";
import { OrderService } from "src/app/features/orders/services/order.service";
import { environmentMapping, phoneNumber } from "src/assets/config/constants";
import { AppBarTitleService } from "../../services/app-bar-title/app-bar-title.service";
import { HelperFunctionService } from "src/app/theme/utils/helper-function.service";
import { PushNotificationService } from "../../services/push-notification/push-notification.service";
import { AppNotifications } from "src/app/models/notification-model";

@Component({
  selector: "app-myuss-wrapper",
  templateUrl: "./myuss-wrapper.component.html",
  styleUrls: ["./myuss-wrapper.component.scss"],
  animations: [rotate],
  providers: [MenuService],
})
export class MyUSSWrapperComponent implements OnInit {
  @ViewChild("sidenav") sidenav: any;
  @ViewChild("backToTop") backToTop: any;
  @ViewChild("mainContent") mainContent: MatDrawerContent;

  public settings: Settings;
  public showSidenav: boolean = false;
  public showInfoContent: boolean = false;
  public toggleSearchBar: boolean = false;
  private defaultMenu: string; //declared for return default menu when window resized
  public menus = ["vertical", "horizontal"];
  public menuOption: string;
  public menuTypes = ["default", "compact", "mini"];
  public menuTypeOption: string;
  subs = new SubscriptionsContainer();
  isMobile: boolean = false;
  public showEmailVerificationMessage = signal(false);
  accountStatus: string | undefined;
  isActiveStatus: boolean = false;
  copyrightDate = new Date().getFullYear();
  helpDocsEnabled:boolean = false;
  isNative: boolean = false;
  technicalSupportEnabled: boolean = false;
  enableFindUnits:boolean = false;
  environmentName:string = "";
  isProduction: boolean = false;
  pageTitle: string = '';
  isPushNotificationActive: boolean = false;
  isMobileView:boolean = false;
  isShowFooter:boolean = false;
  phoneNumber: string="";
  notificationList: AppNotifications[];
  selectedAccountId: string;
  showRedDot: boolean = false;
  currentCount: number


  constructor(
    public appSettings: AppSettings,
    public router: Router,
    private menuService: MenuService,
    public profileService: ProfileService,
    private toastService: ToastrService,
    private configService: ConfigService,
    private platform: Platform,
    private scanService: ScanService,
    private orderService: OrderService,
    private appBarTitleService: AppBarTitleService,
    public helperFunctionService:HelperFunctionService,
    private pushNotificationService: PushNotificationService,

    
  ) {
    this.settings = this.appSettings.settings;
    this.isNative = this.platform.is("hybrid");
    effect(() => {
      this.showRedDot = this.pushNotificationService.notificationBellSignal();
    });
    effect(() => {
      this.selectedAccountId = this.profileService.selectedAccount().accountId as string;
      this.getPushNotifications();
      this.enableFindUnits = this.profileService.selectedAccount().myussModules?.myussAssetScanningEnabled ?? false;
      this.pageTitle = this.appBarTitleService.pageTitleSignal(); 
    });
    effect(()=> {
      this.isShowFooter =this.helperFunctionService.isFooter();
         
      let mainContent = document.querySelector(".main-content-inner") as HTMLElement | null;
      if (this.isMobileView) {
        if (this.platform.is("hybrid")) {
          if (this.isShowFooter) {
            mainContent?.style.setProperty('padding-bottom', '66px');
          } else {
            mainContent?.style.setProperty('padding-bottom', '15px');
          }
        }
      } else {
        mainContent?.style.setProperty('padding-bottom', '15px');
      }
    })
  }

  ngOnInit() {
    console.log("height",window.innerHeight,window.outerHeight)
    this.detectMobileOrWebView();
    this.phoneNumber = phoneNumber;
    this.isNative = this.platform.is("hybrid")?true:false;
    if (this.platform.is("hybrid")) {
      this.enableFindUnits = this.profileService.selectedAccount().myussModules?.myussAssetScanningEnabled ?? false; 

      let mainContent = document.querySelector(".main-content-inner") as HTMLElement | null;
      let mainDrawerContainer=document.querySelector(".mat-drawer-content") as HTMLElement | null;
      if (this.platform.is("ios")) {
        mainContent?.style.setProperty("padding-bottom", "20px");
        document.getElementById("mainSection")?.classList.add("top-safe");
      }
      mainDrawerContainer?.style.setProperty("padding-bottom", "0px", "important");
    }
    if (window.innerWidth <= 960) {
      this.isMobile = true;
      this.settings.menu = "vertical";
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
    }
    this.menuOption = this.settings.menu;
    this.menuTypeOption = this.settings.menuType;
    this.defaultMenu = this.settings.menu;
    this.subscribeToUserProfile();
    this.helpDocsEnabled = this.configService.getConfigProperty('HELP_DOCS_ENABLED');
    this.technicalSupportEnabled = this.configService.getConfigProperty('TECHNICAL_SUPPORT_ENABLED');
    this.enableFindUnits = this.profileService.selectedAccount().myussModules?.myussAssetScanningEnabled ?? false;
    ;    

    this.resetUnitNumberState();

    this.isProduction = this.configService.getConfigProperty('production');

    this.environmentName = environmentMapping[this.configService.getConfigProperty("environment")];

  }
  getAltText(): string {
    return this.showSidenav ? 'Collapses the menu to hide options.' : 'Expands the menu to show more options.';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    }, 300);
    if(this.backToTop){
    this.backToTop.nativeElement.style.display = "none";
  }
    this.subs.add = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.scrollToTop();
      }
      if (window.innerWidth <= 960) {
        this.sidenav?.close();
        
      }
    });
    if (this.settings.menu == "vertical")
      this.menuService.expandActiveSubMenu(
        this.menuService.getVerticalMenuItems()
      );
  }

  ngOnDestroy() {
    this.subs.dispose();
  }

  //check if single module is eanbled

  checkSingleModuleEnabled(): number {
    let count = 0;
    if (this.profileService?.selectedAccount().myussModules?.myussBillingEnabled) count++;
    if (this.profileService?.selectedAccount().myussModules?.myussCasesEnabled) count++;
    if (this.profileService?.selectedAccount().myussModules?.myussEasyPayEnabled) count++;
    if (this.profileService?.selectedAccount().myussModules?.myussHomeEnabled) count++;
    if (this.profileService?.selectedAccount().myussModules?.myussOrdersEnabled) count++;
    if (this.profileService?.selectedAccount().myussModules?.myussProjectsEnabled) count++;
    if (this.profileService?.selectedAccount().myussModules?.myussQuotesEnabled) count++;
    return count;
  }
  toggleSideNavBar() {
    if (!this.isMobile) {
      const drawer = document.querySelector(
        ".mat-drawer-content"
      ) as HTMLElement;
      if (this.checkSingleModuleEnabled() === 1) {
        this.settings.menuType = "mini";
        drawer ? (drawer.style.marginLeft = "66px") : null;
      } else {
        this.settings.menuType = "default";
        drawer ? (drawer.style.marginLeft = "220px") : null;
      }
    }
  }

  public toggleSidenav() {
    this.sidenav?.toggle();
  }

  public chooseMenu() {
    this.settings.menu = this.menuOption;
    this.defaultMenu = this.menuOption;
    if (this.menuOption == "horizontal") {
      this.settings.fixedSidenav = false;
    }
    this.router.navigate(["/"]);
  }

  public chooseMenuType() {
    this.settings.menuType = this.menuTypeOption;
  }

  public changeTheme(theme: string) {
    this.settings.theme = theme;
  }

  public closeInfoContent(showInfoContent: boolean) {
    this.showInfoContent = !showInfoContent;
  }

  @HostListener("window:resize") onWindowResize(): void {
    if (window.innerWidth <= 960) {
      this.isMobile = true;
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
      this.settings.menu = "vertical";
    } else {
      this.isMobile = false;
      this.defaultMenu == "horizontal"
        ? (this.settings.menu = "horizontal")
        : (this.settings.menu = "vertical");
      this.settings.sidenavIsOpened = true;
      this.settings.sidenavIsPinned = true;
    }
  }

  public scrollToTop() {
    if (this.settings.fixedHeader && this.settings.fixedSidenav) {
      this.mainContent.scrollTo({ top: 0, left: 0, behavior: "auto" });
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    }
  }

  public closeSubMenus(e) {
    if(this.isNative){
      e.preventDefault();
    }
    if (this.settings.menu == "vertical") {
      this.menuService.closeAllSubMenus();
    }
  }

  onMainContentScroll(event: any) {
    this.toggleBackToTop(event.target.scrollTop);
  }

  @HostListener("window:scroll") onWindowScroll(): void {
    this.toggleBackToTop(window.scrollY);
  }

  toggleBackToTop(value: number) {
    value > 300
      ? (this.backToTop.nativeElement.style.display = "flex")
      : (this.backToTop.nativeElement.style.display = "none");
  }

  collapse() {
    const drawer = document.querySelector(".mat-drawer-content") as HTMLElement;
    if (!this.isMobile) {
      if (this.settings.menuType == "mini") {
        this.settings.menuType = "default";
        drawer ? (drawer.style.marginLeft = "220px") : null;
        return;
      }

      this.settings.menuType = "mini";
      drawer ? (drawer.style.marginLeft = "66px") : null;
      return;
    } else {
      this.settings.menuType = "default";
      drawer ? (drawer.style.marginLeft = "0px") : null;
      this.sidenav.toggle();      
    }
  }

  private subscribeToUserProfile() {
    this.subs.add = this.profileService.userProfile$.subscribe((userProfile) => {
      this.isPushNotificationActive = userProfile.isPushNotificationActive || false;
      if (userProfile?.accountId && !userProfile?.emailVerified) {
        this.showEmailVerificationMessage.set(true);
      } else {
        this.showEmailVerificationMessage.set(false);
      }
    });
  }

  public requestEmailConfirmation() {
    this.profileService
      .requestEmailConfirmation()
      .then((response) => {
        this.toastService.success(
          `Email verification sent to ${this.profileService.userProfile$.value.email}.`
        );
      })
      .catch((error) => {
        this.toastService.error("Error sending email verification.");
        console.log(error);
      });
  }

  navigateToScanAssetTag() {
    this.router.navigate(['/scan-asset-tag']);
  }

  /**
   * Reset the unit number state when the user navigates away from the scan/asset-details page
   */
  resetUnitNumberState(){
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
       if(!event.url.includes("scan/assest-details") && !event.url.includes("edit-order") ){
        this.scanService.setAssetDetails(null);
        this.orderService.orderSummaryBehaviorSubject.next(null);
       }
      }
    });
  }

  detectMobileOrWebView() {
    const mobileQuery = "(max-width: 767px)";
    const webQuery = "(min-width: 768px)";

    // Detect mobile view with a dynamic media query
    this.helperFunctionService
      .isViewportMatch(mobileQuery)
      .subscribe((result) => {
        if (result.matches) {
          this.isMobileView = true;
        }
      });

    // Detect web view with a dynamic media query
    this.helperFunctionService.isViewportMatch(webQuery).subscribe((result) => {
      if (result.matches) {
        this.isMobileView = false;
      }
    });

    
  }


  navigateToPushNotification() {
    this.showRedDot = false;
    this.pushNotificationService.resetNotificationFlag();
    localStorage.setItem('notificationCount', this.currentCount.toString());
    this.router.navigate(['home/push-notification']);
  }

  getPushNotifications() {
    this.pushNotificationService
      .getPushNotifications(this.selectedAccountId)
      .subscribe({
        next: (res) => {
          if (res) {
            this.notificationList = res.sort((a, b) => {
              return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
              );
            });
          this.currentCount = this.notificationList.length;
          const previousCount = parseInt(localStorage.getItem('notificationCount') || '0', 10);
          this.showRedDot = this.currentCount > previousCount;
          }
        },
        error: (error) => {
          this.toastService.error("Error fetching notifications:", error);
        },
      });
  }
}
import { Component, effect, Inject } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
  AbstractControl,
} from "@angular/forms";
import { GetContactModel } from "src/app/models/site-details-model";
import { QuoteStateService } from "src/app/features/quotes/services/quote-state.service";
import { Subscription, distinctUntilChanged, filter } from "rxjs";
import { AccountService } from "src/app/features/accounts/services/account/account.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { QuoteState } from "src/app/models/quoteState-model";
import { ToastrService } from "ngx-toastr";
import { QuoteCreationService } from "src/app/features/quotes/services/quote-creation.service";
import { AddLocation, DeleteLocationReq, GetAddressModel } from "src/app/models/address-model";
import { is } from "date-fns/locale";
import { EnhanceQuoteService } from "src/app/features/enhance-quotes/services/enhance-quote.service";
import { QuoteModelDetailsV2 } from "src/app/models/enhance-quote-model";
import { JobsiteModel } from "src/app/models/jobsite-model";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { Document } from "src/app/models/case-details-model";

@Component({
  selector: "app-add-location-popup",
  templateUrl: "./add-location-popup.component.html",
  styleUrls: ["./add-location-popup.component.scss"],
})
export class AddLocationPopupComponent {
  addLocationForm: FormGroup;
  savedContacts: GetContactModel[] | [] = [];
  isEdit: boolean = true;
  isSameAsMainSite: boolean = false;
  savedSites: GetAddressModel[] = [];
  // quoteStateSubscription: Subscription;
  quoteStateSubscription: Subscription = new Subscription();
  currentValue: QuoteState;
  startTime: string = "";
  endTime: string = "";
  quoteState: QuoteModelDetailsV2;
  enhanceQuoteUiFlag : boolean 
  attachments: Document[]=[];


  constructor(
    public router: Router,
    private toastr: ToastrService,
    public fb: FormBuilder,
    public quoteStateService: QuoteStateService,
    public quoteCreateService: QuoteCreationService,
    private accountService: AccountService,
    public profileService: ProfileService,
    public dialogRef: MatDialogRef<AddLocationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public locationData: { type: string,column: number; subSiteDetails: JobsiteModel; subsiteLength: number },
    private enhancedQuoteService: EnhanceQuoteService,
    public quoteCreationService: QuoteCreationService,
    

  ) {
    effect(() => {
      this.quoteState = this.enhancedQuoteService.quoteState();

    })
    effect(()=>{
      this.enhanceQuoteUiFlag = this.enhancedQuoteService.enhanceQuoteUiSignal()
      if(this.locationData == null){
        this.sameAsMainSite();
      }else{
        this.isSameAsMainSite = false;
      }
    })
      this.addLocationForm = this.fb.group(
      {
        placementNotes: ["", Validators.required],
        id: [""],
        siteName: ["", Validators.required],
        siteHoursStart: [""],
        siteHoursEnd: [""],
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        email: [
          "",
          [
            Validators.required,
            Validators.email,
            Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"),
          ],
        ],
        phone: [
          "",
          Validators.compose([Validators.required, Validators.minLength(10)]),
        ],
        contactId: [""],
        // accountId: this.profileService.selectedAccount().accountId,
      },
      { validators: this.startTimeAndEndTimeValidator }
    );
  }

  ngOnInit() {
    if (this.locationData) {
      if (this.locationData.type === "view") {
        if (this.locationData?.subSiteDetails.address.startTime) {
          this.startTime =
            this.locationData?.subSiteDetails.address.startTime.split(
              "."
            )[0];
          this.startTime =
            this.startTime?.split(":")[0] + ":" + this.startTime?.split(":")[1];
        } else {
          this.startTime = "";
        }
        if (this.locationData?.subSiteDetails.address.endTime) {
          this.endTime =
            this.locationData?.subSiteDetails.address.endTime.split(".")[0];
          this.endTime =
            this.endTime?.split(":")[0] + ":" + this.endTime?.split(":")[1];
        } else {
          this.endTime = "";
        }
        this.attachments = this.locationData.subSiteDetails.attachments ?? [];
        if(this.attachments[0]){
          this.quoteCreateService.fileIdSignal.set(this.attachments[0]);
        }
        this.isEdit = false;
        this.addLocationForm.patchValue(
          {
            placementNotes:
              this.locationData?.subSiteDetails.address.instructions,
            siteName: this.locationData?.subSiteDetails.address.siteName,
            id: this.locationData?.subSiteDetails.address.id,
            siteHoursStart: this.startTime,
            siteHoursEnd: this.endTime,
            firstName: this.locationData?.subSiteDetails.contact.firstName,
            lastName: this.locationData?.subSiteDetails.contact.lastName,
            email: this.locationData?.subSiteDetails.contact.email,
            phone: this.locationData?.subSiteDetails.contact.phone,
            contactId: this.locationData?.subSiteDetails.contact.contactId,
          },
          { emitEvent: false, onlySelf: true }
        );
      }
    }

    //  this.isEdit = this.locationData?.subSiteDetails.isEdit;
    this.quoteStateSubscription = this.quoteStateService.currentQuote$
      .pipe(
        filter((el) => el.currentStep === 3),
        distinctUntilChanged(
          (prev, curr) => prev.currentStep === curr.currentStep
        )
      )
      .subscribe((res) => {
        this.currentValue = res;
        //fecthing contacts
        this.accountService.fetchContacts().subscribe((result) => {
          if (result) {
            this.savedContacts = result;
          }
        });
        this.accountService
          .fetchSiteLocation(this.currentValue.address?.addressId as string)
          .subscribe((res:GetAddressModel[]) => {
            this.savedSites = res;
          });
         
      });
      this.attachments = this.locationData.subSiteDetails?.attachments ?? [];

    this.getSavedContacts();
    this.vauleChange("firstName");
    this.vauleChange("lastName");
    this.vauleChange("email");
    this.vauleChange("phone");
    this.vauleChange("siteHoursStart");
    this.vauleChange("siteHoursEnd");
    this.vauleChange("siteName");
    
  }

  getSavedContacts() {
    //fecthing contacts
    this.accountService.selectedAccountContacts$.subscribe((result) => {
     if (result) {
       console.log("fetching getSavedContacts billing details",result);
       this.savedContacts = result;
     }
    }); 
 }

  onkey(e) {
    this.addLocationForm.patchValue(
      { phone: e.replace(/[^0-9]/g, "") },
      { emitEvent: false, onlySelf: true }
    );
  }

  async deletePopup() {

    if (
      this.locationData.subSiteDetails.address.id &&
      this.locationData.subsiteLength > 1
    ) {
      const requestId = await this.quoteStateService.generateRequestID();
      const data: DeleteLocationReq = {
        requestId,
        addressId: this.locationData.subSiteDetails.address.id,
        quoteId: this.enhanceQuoteUiFlag? `${this.quoteState.quoteId}`:`${this.currentValue.quoteId}`,
      };
      this.quoteCreateService.deleteLocation(data).subscribe((res) => {
        if (res) {
          this.toastr.success("Location deleted successfully.");
          this.dialogRef.close(true);
        }
      });
    } else {
      this.dialogRef.close(true);
    }
  }
  cancelPopup() {
    this.dialogRef.close(false);
  }

  editPopup() {
    this.isEdit = true;
  }

  sameAsMainSite() {
    this.isSameAsMainSite = !this.isSameAsMainSite;
    
    if(!this.enhanceQuoteUiFlag && this.currentValue.step3){
      if (this.isSameAsMainSite) {
        if (this.currentValue.step3.SiteDetails?.startTime) {
          this.startTime =
            this.currentValue.step3.SiteDetails?.startTime?.split(".")[0];
          this.startTime =
            this.startTime?.split(":")[0] + ":" + this.startTime?.split(":")[1];
        } else {
          this.startTime = "";
        }
        if (this.currentValue.step3.SiteDetails?.endTime) {
          this.endTime =
            this.currentValue.step3.SiteDetails?.endTime?.split(".")[0];
          this.endTime =
            this.endTime?.split(":")[0] + ":" + this.endTime?.split(":")[1];
        } else {
          this.endTime = "";
        }
  
        this.addLocationForm.patchValue(
          {
            siteHoursStart: this.startTime,
            siteHoursEnd: this.endTime,
            firstName: this.currentValue.step3.contactData?.firstName,
            lastName: this.currentValue.step3.contactData?.lastName,
            email: this.currentValue.step3.contactData?.email,
            phone: this.currentValue.step3.contactData?.phone,
            contactId: this.currentValue.step3.contactData?.contactId,
          },
          { emitEvent: false, onlySelf: true }
        );
      } else {
        this.addLocationForm.patchValue(
          {
            siteHoursStart: "",
            siteHoursEnd: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            contactId: "",
          },
          { emitEvent: false, onlySelf: true }
        );
      }

    }

    if(this.enhanceQuoteUiFlag){
      if (this.isSameAsMainSite) {
        if (this.quoteState?.shippingAddress?.startTime) {
          this.startTime =
          this.quoteState?.shippingAddress?.startTime?.split(".")[0];
          this.startTime =
            this.startTime?.split(":")[0] + ":" + this.startTime?.split(":")[1];
        } else {
          this.startTime = "";
        }
        if (this.quoteState?.shippingAddress?.endTime) {
          this.endTime =
          this.quoteState?.shippingAddress?.endTime?.split(".")[0];
          this.endTime =
            this.endTime?.split(":")[0] + ":" + this.endTime?.split(":")[1];
        } else {
          this.endTime = "";
        }
  
        this.addLocationForm.patchValue(
          {
            siteHoursStart: this.startTime,
            siteHoursEnd: this.endTime,
            firstName: this.quoteState?.primaryContact?.firstName,
            lastName: this.quoteState?.primaryContact?.lastName,
            email: this.quoteState?.primaryContact?.email,
            phone: this.quoteState?.primaryContact.phone,
            contactId: this.quoteState?.primaryContact?.id,
          },
          { emitEvent: false, onlySelf: true }
        );
      } else {
        this.addLocationForm.patchValue(
          {
            siteHoursStart: "",
            siteHoursEnd: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            contactId: "",
          },
          { emitEvent: false, onlySelf: true }
        );
      }
    }
  
  }

  createLocation(): void {
    // Check if form is valid
    if (this.addLocationForm.valid) {
      // Close the dialog and pass the form data
      let data : AddLocation
      if (this.addLocationForm.value.siteHoursStart) {
        this.startTime = this.addLocationForm.value.siteHoursStart + ":00.125Z";
      }

      if (this.addLocationForm.value.siteHoursEnd) {
        this.endTime = this.addLocationForm.value.siteHoursEnd + ":00.125Z";
      }

      if(this.enhanceQuoteUiFlag){
        data = this.locationDataMapperEnhanceQuoteState()
      }else{
        data = this.locationDataMapper();
      }
      this.accountService.addLocation(data).subscribe((res) => {
        if (res) {
          this.toastr.success("Location added successfully.");
          data.id = res.addressId;
          data.address.id = res.addressId;
          data.contact.contactId = res.contactId;
          data.address.fileId = this.quoteCreationService.fileIdSignal()?.docId
          this.accountService.fetchContacts().subscribe((result) => {
            if (result) {
              this.savedContacts = result;
            }
          });
          this.quoteCreationService.fileIdSignal.set(null);
          this.dialogRef.close(data);
        }
      });
    } else {
      this.handleFormErrors();
    }
  }

  selectFromSavedContacts(contact) {
    // Patch form values with selected contact data
    this.isSameAsMainSite = false;

    this.addLocationForm.patchValue(
      {
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        phone: contact.phone,
        contactId: contact.contactId,
      },
      { emitEvent: false, onlySelf: true }
    );
  }

  onSave() {
    // Check if form is valid
    if (this.addLocationForm.valid) {
      let data : AddLocation
      if (this.addLocationForm.value.siteHoursStart) {
        this.startTime = this.addLocationForm.value.siteHoursStart + ":00.125Z";
      }

      if (this.addLocationForm.value.siteHoursEnd) {
        this.endTime = this.addLocationForm.value.siteHoursEnd + ":00.125Z";
      }
      if(this.enhanceQuoteUiFlag){
        data = this.locationDataMapperEnhanceQuoteState()
      }else{
        data = this.locationDataMapper();
      }

      this.accountService.updateLocation(data).subscribe((res) => {
        if (res) {
          this.toastr.success("Location updated successfully.");
          data.id = res.addressId;
          data.address.id = res.addressId;
          data.contact.contactId = res.contactId;
          data.address.fileId = this.quoteCreationService.fileIdSignal()?.docId
          console.log("data.address.fileId",data.address.fileId);
          this.accountService.fetchContacts().subscribe((result) => {
            if (result) {
              this.savedContacts = result;
            }
          });
          this.quoteCreationService.fileIdSignal.set(null);
          // Close the dialog and pass the form data
          this.dialogRef.close({
            column: this.locationData?.column,
            subSiteDetails: data,
          });
        }
      });
    } else {
      this.handleFormErrors();
    }
  }

  handleFormErrors() {
    // Mark all fields as touched to display validation errors
    this.addLocationForm.markAllAsTouched();
    // Display error messages based on form errors
    if (this.addLocationForm.errors?.insufficientTimeGap) {
      this.toastr.error(
        "Start Time & End Time should have atleast 2 hours difference."
      );
    }
    if (this.addLocationForm.errors?.incompleteTime) {
      this.toastr.error(
        "Please enter start time and end time for the site hours."
      );
    }
    if (this.addLocationForm.errors?.invalidTime) {
      this.toastr.error(
        "Site hours Start Time should be earlier than End time."
      );
    }
  }

  startTimeAndEndTimeValidator = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const startTime = control.get("siteHoursStart")?.value;
    const endTime = control.get("siteHoursEnd")?.value;

    return this.quoteStateService.startTimeAndEndTimeValidator(
      startTime,
      endTime
    );
  };

  vauleChange(field: string) {
    this.addLocationForm.get(field)?.valueChanges.subscribe((res) => {
      if (["firstName", "lastName", "email", "phone"].includes(field)) {
        this.addLocationForm.get("contactId")?.setValue("");
      }
      if(["firstName", "lastName", "email", "phone","siteHoursStart","siteHoursEnd"].includes(field)){
        this.isSameAsMainSite = false;
      }
      if(field == 'siteName'){
        if(this.locationData?.subSiteDetails?.address?.id){
          this.addLocationForm.get("id")?.setValue(this.locationData.subSiteDetails.address.id);
        }else{
          this.addLocationForm.get("id")?.setValue("");
        }
      }
      if (field === 'email' ) {
        this.checkForExistingContact();
      }
    });
  }

  checkForExistingContact() {
    const formValues = this.addLocationForm.value;
    this.quoteCreateService.checkForExistingContact(formValues, this.savedContacts)
      .subscribe(result => {
        if (result?.dialogData.action === 'choose') {
          this.selectFromSavedContacts(result.contact);
        }
      });
  }


  locationDataMapper() {
    let attchments :Document[]=[];
    attchments.push({
      docId:this.quoteCreateService.fileIdSignal()?.docId ||'',
      title:this.quoteCreateService.fileIdSignal()?.title ||''
    });
    const data: AddLocation = {
      id: this.addLocationForm.value.id,
      contactId: this.addLocationForm.value.contactId,
      contactExist: this.addLocationForm.value.contactId ? true : false,
      contact: {
        contactId: this.addLocationForm.value.contactId,
        accountId:
          this.quoteStateService.getCurrentValue().userProfile?.accountId,
        firstName: this.addLocationForm.value.firstName,
        lastName: this.addLocationForm.value.lastName,
        email: this.addLocationForm.value.email,
        phone: this.addLocationForm.value.phone,
      },
      attachments:attchments,
      address: {
        id: this.addLocationForm.value.id,
        accountId: `${
          this.quoteStateService.getCurrentValue().userProfile?.accountId
        }`,
        fileId:this.quoteCreateService.fileIdSignal()?.docId ??'',
        city: this.quoteStateService.getCurrentValue().address.city,
        country: this.quoteStateService.getCurrentValue().address.country,
        state: this.quoteStateService.getCurrentValue().address.state,
        street: this.quoteStateService.getCurrentValue().address.street,
        zipcode: this.quoteStateService.getCurrentValue().address.zipcode,
        siteName: this.addLocationForm.value.siteName,
        instructions: this.addLocationForm.value.placementNotes,
        startTime: this.startTime,
        endTime: this.endTime,
        shipToAddress: true,
        parentRefId: this.quoteStateService.getCurrentValue().address.addressId,
        latitude: this.quoteStateService.getCurrentValue().address.latitude,
        longitude: this.quoteStateService.getCurrentValue().address.longitude,
      },
    };

    return data;
  }


  locationDataMapperEnhanceQuoteState(){
    const data: AddLocation = {
      id: this.addLocationForm.value.id,
      contactId: this.addLocationForm.value.contactId,
      contactExist: this.addLocationForm.value.contactId ? true : false,
      contact: {
        contactId: this.addLocationForm.value.contactId,
        accountId: `${this.profileService.selectedAccount().accountId}`,

        firstName: this.addLocationForm.value.firstName,
        lastName: this.addLocationForm.value.lastName,
        email: this.addLocationForm.value.email,
        phone: this.addLocationForm.value.phone,
      },
      address: {
        id: this.addLocationForm.value.id,
        accountId: `${this.profileService.selectedAccount().accountId}`,
        city: this.quoteState.shippingAddress.city,
        country: this.quoteState.shippingAddress.country,
        state: this.quoteState.shippingAddress.state,
        street: this.quoteState.shippingAddress.street,
        zipcode: this.quoteState.shippingAddress.zipcode,
        siteName: this.addLocationForm.value.siteName,
        instructions: this.addLocationForm.value.placementNotes,
        startTime: this.startTime,
        endTime: this.endTime,
        shipToAddress: true,
        parentRefId: this.quoteState.shippingAddress.id,
        latitude: this.quoteState.shippingAddress.latitude,
        longitude:this.quoteState.shippingAddress.longitude,
      },
    };

    return data;
  }

  siteStartTime(e) {
    this.startTime = e.target.value;
  }

  siteEndTime(e) {
    this.endTime = e.target.value;
  }


}

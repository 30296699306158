import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { QuoteCreationService } from "src/app/features/quotes/services/quote-creation.service";
import { QuoteStateService } from "src/app/features/quotes/services/quote-state.service";
import { LoadingService } from "../../services/loading/loading.service";
import { ToastrService } from "ngx-toastr";
import { Document } from "src/app/models/case-details-model";

@Component({
  selector: "app-browse-preview",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./browse-preview.component.html",
  styleUrl: "./browse-preview.component.scss",
})
export class BrowsePreviewComponent {
  @Input() attchment: Document[] = [];
  fileName: string = "";
  fileId: string = "";
  errorMessage: string = "";
  fileIconPath: string = "";

  constructor(
    private quoteStateService: QuoteStateService,
    private profileService: ProfileService,
    private quoteCreationService: QuoteCreationService,
    private toastr: ToastrService,
    private loadingService: LoadingService
  ) {}

  async ngOnInit() {
 
  }
  triggerFileUpload(): void {
    const fileInput = document.getElementById(
      "file-upload"
    ) as HTMLInputElement;
    fileInput.click();
  }

  ngOnChanges() {
    this.attchment.forEach((attach) => {
      this.fileName = attach.title;
      this.fileId = attach.docId;
      const fileExtension = this.fileName.split(".").pop()?.toLowerCase();

      if (fileExtension) {
        this.errorMessage = "";
        this.fileIconPath = this.quoteStateService.getImageByExtension(fileExtension);
      } else {
        this.fileIconPath = this.quoteStateService.getImageByExtension(undefined);
      }
    });
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];
      const maxFileSize = 5 * 1024 * 1024;

      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
        this.errorMessage = "Unsupported file format. Please upload file in supported format.";
        this.fileName = "";
        input.value = "";
      } else if (file.size > maxFileSize) {
        this.errorMessage = "Uploaded file exceeds the 5 MB size limit. Please upload a smaller file.";
        this.fileName = "";
        input.value = "";
      } else {
        this.errorMessage = "";
        this.fileName = file.name;
        this.fileIconPath = this.quoteStateService.getImageByExtension(fileExtension);
        this.uploadFile(file);
      }
    } else {
      this.fileName = "";
      this.fileIconPath = "";
      this.errorMessage = "No file selected.";
    }
  }

  // Method to upload file
  uploadFile(file: File): void {
    const formData = new FormData();
    formData.append("file", file, file.name);

    this.quoteStateService
      .uploadAttchment(
        `${this.profileService.selectedAccount().accountId}`,
        formData
      )
      .subscribe((res) => {
        let attachments:Document ={
        docId:res.data.fileId,
        title:res.data.fileName
        }
        this.quoteCreationService.fileIdSignal.set(attachments);
        this.fileId = attachments.docId;
      });
  }

  downloadFile(): void {
    this.quoteStateService
      .downloadFile(
        `${this.profileService.selectedAccount().accountId}`,
        `${this.fileId}`
      )
      .subscribe(async (blob) => {
        if (blob) {
          if (Capacitor.isNativePlatform()) {
            this.loadingService.startLoading(true, 'Downloading file...', true);
            const base64Data = await this.convertBlobToBase64(blob);
              await Filesystem.writeFile({
              path: this.fileName,
              data: base64Data,
              directory: Directory.Documents,
            });
            this.loadingService.startLoading(false);
            this.toastr.success(`File ${this.fileName} saved to directory.`);
          }
          else{
            this.saveFile(blob, this.fileName);
          }
        } else console.log("Error downloading file");
      });
  }

  private saveFile(blob: Blob, fileName: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  private convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        const base64String = (reader.result as string).split(",")[1]; // Extract the base64 part
        resolve(base64String);
      };
      reader.readAsDataURL(blob);
    });
  }
}

import { Injectable, signal } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  map,
  catchError,
  of,
  firstValueFrom,
} from "rxjs";
import { ProductModel } from "../../../models/product-model";
import { StandardQuotes } from "../../../models/quote-main-screen-model";
import {
  UnitAndServiceModel,
  updateQuoteSummaryReq,
  CreateQuoteReq,
  updateQuoteSummaryRes,
  CreateQuoteRes,
} from "../../../models/unit-and-services-model";
import { LoadingService } from "../../../shared/services/loading/loading.service";
import { UssApiService } from "../../myuss/services/uss-api/uss-api.service";
import {
  AcceptAndRejectResponse,
  PDFAcceptAndReject,
} from "../../../models/viewandaccept-model";
import {
  DeleteLocationReq,
} from "../../../models/address-model";
import {
  SiteDetail,
  GetContactModel,
  ResponseSiteDetails,
  GetSiteDetailResponseModel,
  RequestSiteDetails,
  DialogResult,
} from "../../../models/site-details-model";
import {
  BillingDetailsResponse,
  FetchAddressData,
  BillingDetailsRequest,
  FetchBillingDetails,
} from "../../../models/billing-details-model";
import { ConfirmationPopupComponent } from "../../../shared/components/dialogbox/confirmation-popup/confirmation-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ConfirmQuoteModel } from "src/app/models/quoteState-model";
import { ConfigService } from "src/app/shared/services/config/config.service";
import { SiteAddressExistPopupComponent } from "src/app/shared/components/dialogbox/site-address-exist-popup/site-address-exist-popup.component";
import { ApiRespDTO } from "src/app/models/api.model";
import { Document } from "src/app/models/case-details-model";

@Injectable({
  providedIn: "root",
})
export class QuoteCreationService {
  public quoteHasError$ = new BehaviorSubject<boolean | null>(null);
  public fileIdSignal = signal<Document | null>(null);

  constructor(
    private api: UssApiService,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    public router: Router,
    private configService: ConfigService,
  ) {}

  /**
   *unit & services
   */
  // Products behavior subject
  private productsBehaviorSubject = new BehaviorSubject<ProductModel[]>(
    new Array<ProductModel>()
  );
  // allproducts as Observable
  allProducts: Observable<ProductModel[]> =
    this.productsBehaviorSubject.asObservable();

  /**
   * Standard quote
   */
  standardQuote = new BehaviorSubject<StandardQuotes | null>(null);
  /**
   * Unit and services as Observable
   */
  standardQuote$: Observable<StandardQuotes | null> =
    this.standardQuote.asObservable();

  // fetch all products
  getProducts() {
    return this.api.get("products/list").pipe(
      map((res) => {
        if (res["status"] === 1000) {
          return res["data"]["bundle"];
        }
      }),
      catchError((err) => {
        console.error(`error getting products: ${JSON.stringify(err)}`);
        throw err;
        // return of(null);
      })
    );
  }

  // new implementation
  createQuote(data: CreateQuoteReq): Observable<CreateQuoteRes | null> {
    if (data.accountId === null) {
      const res = new CreateQuoteRes();
      res.status = 1018;
      return of(res);
    }
    return this.api.post("quotes/create-quote", data).pipe(
      map((res) => {
        if (res["status"] === 1000 || res["status"] === 1018) {
          return res;
        } else {
          return null;
        }
      }),
      catchError((err) => {
        localStorage.removeItem("requestId");
        console.error(`error requesting quote: ${JSON.stringify(err)}`);
        throw err;
        // return of(null);
      })
    );
  }

  // update quote summary
  updateQuoteSummary(
    data: updateQuoteSummaryReq
  ): Observable<updateQuoteSummaryRes | null> {
    return this.api.post("quotes/update-quote", data).pipe(
      map((res) => {
        if (res["status"] === 1000) {
          return res["data"];
        } else {
          return null;
        }
      }),
      catchError((err) => {
        console.error(`error requesting quote: ${JSON.stringify(err)}`);
        throw err;
        // return of(null);
      })
    );
  }

  //request a qoute
  generateDocument(qouteId: string, requestId: string) {
    const endPoint = this.configService.getConfigProperty("QUOTE_HTML")
      ? "quote-html"
      : "document";
    return this.api
      .post(
        `quotes/${qouteId}/${endPoint}?quotehtml=${this.configService.getConfigProperty(
          "QUOTE_HTML"
        )}`,
        {
          requestId,
        }
      )
      .pipe(
        map((res) => {
          if (res["status"] === 1000 || res["status"] === 1018) {
            console.log("response returned by", res);
            return res;
          }
          return null;
        }),
        catchError((err) => {
          // this.toastr.error("Something went wrong.");
          localStorage.removeItem("generateDocumentId");
          console.error(`error requesting quote: ${JSON.stringify(err)}`);
          throw err;
          // return of(null);
        })
      );
  }
  //get qoute data by id
  getQuoteData(qouteId: string) {
    return this.api.get(`quotes/${qouteId}`, {}).pipe(
      map((res) => {
        if (res["status"] === 1000) {
          return res["data"];
        }
      }),
      catchError((err) => {
        // this.toastr.error("Something went wrong.");
        console.error(`error requesting quote: ${JSON.stringify(err)}`);
        throw err;
        // return of(null);
      })
    );
  }
  //

  requestQuote(data: UnitAndServiceModel) {
    this.loadingService.setLoader(true, "Saving Quote", true);
    // await firstValueFrom(
    return this.api.post("quote/unit_services", data).pipe(
      map((res) => {
        if (res["status"] === 200) {
          // this.unitAndServicesBehaviorSubject.next(res);
          // this.calculateQuote(res["data"]["quoteId"]);
          this.loadingService.setLoader(true, res["data"]["nextStep"], true);
          return res;
        } else {
          // this.toastr.error("Something went wrong.");
        }
        // this.loadingService.setLoader(false);
      }),
      catchError((err) => {
        // this.toastr.error("Something went wrong.");
        console.error(`error requesting quote: ${JSON.stringify(err)}`);
        this.loadingService.setLoader(false);
        throw err;
        // return of(null);
      })
    );
    // );
  }

  calculateQuote(quoteId: string) {
    return this.api.put(`quote/${quoteId}/calculate`, {}).pipe(
      map((res) => {
        if (res["status"] === 200) {
          // this.unitAndServicesBehaviorSubject.next(res);
          this.loadingService.setLoader(true, res["data"].nextStep, true);

          // this.calculateQuoteTax(quoteId);
          return res;
        }
      }),
      catchError((err) => {
        // this.toastr.error("Something went wrong.");
        console.error(`error requesting quote: ${JSON.stringify(err)}`);
        this.loadingService.setLoader(false);
        throw err;
        // return of(null);
      })
    );
  }
  calculateQuoteTax(quoteId: string) {
    return this.api.put(`quote/${quoteId}/calculate-tax`, {}).pipe(
      map((res) => {
        if (res["status"] === 200) {
          // this.unitAndServicesBehaviorSubject.next(res);
          this.loadingService.setLoader(true, res["data"].nextStep, true);
          // this.quoteDocument(quoteId);
          return res;
        }
      }),
      catchError((err) => {
        // this.toastr.error("Something went wrong.");
        console.error(`error requesting quote: ${JSON.stringify(err)}`);
        this.loadingService.setLoader(false);
        throw err;
        // return of(null);
      })
    );
  }

  quoteDocument(quoteId: string) {
    return this.api.post(`quote/${quoteId}/quote-document`, {}).pipe(
      map((res) => {
        if (res["status"] === 200) {
          // this.unitAndServicesBehaviorSubject.next(res);
          this.loadingService.setLoader(false);
          return res;
          // this.calculateQuoteTax(quoteId);
        }
      }),
      catchError((err) => {
        // this.toastr.error("Something went wrong.");
        console.error(`error quote Document quote: ${JSON.stringify(err)}`);
        this.loadingService.setLoader(false);
        throw err;
        // return of(null);
      })
    );
  }

  /**
   * view and accept
   */

  // fetch all products
  getPDF(quoteId: string, documentId: string) {
    this.loadingService.setLoader(false);
    return this.api
      .getPdfBlob(`quotes/${quoteId}/documents/${documentId}`)

      .pipe(
        map((res) => {
          if (res) {
            const mediaType = "application/pdf";
            const blob = new Blob([res], { type: mediaType });
            //console.log("getPDF: ", blob);
            // this.quotePdf$.next(blob);
            return blob;
          }
          return;
        }),
        catchError((err) => {
          console.error(`error getting quote PDF: ${JSON.stringify(err)}`);
          this.loadingService.setLoader(false);
          // throw err; >> use this to rethrow the error if that makes sense
          return of(null);
        })
      );
  }

  onAcceptAndReject(data: PDFAcceptAndReject) {
    // this.loadingService.setLoader(true);
    return this.api.post("quotes/update-status", data).pipe(
      map((res: AcceptAndRejectResponse) => {
        if (res) {
          // this.quoteAcceptReject.next(res);
          // this.loadingService.setLoader(false);
          return res;
        }
        return null;
      }),
      catchError((err) => {
        console.error(`error getting: ${JSON.stringify(err)}`);
        // this.loadingService.setLoader(false);
        // throw err; >> use this to rethrow the error if that makes sense
        return of(null);
      })
    );
  }

  getDocumentID(quoteId: string, requestId: string): Observable<any> {
    this.loadingService.setLoader(false);
    this.loadingService.startLoading(false);
    return this.api
      .post(
        `quotes/${quoteId}/document?quotehtml=${this.configService.getConfigProperty(
          "QUOTE_HTML"
        )}`,

        { requestId }
      )
      .pipe(
        map((res) => {
          if (res["status"] === 1000) {
            return res;
          } else {
            return null;
          }
        }),
        catchError((err) => {
          console.error(`error getting documentID: ${JSON.stringify(err)}`);
          return of(null);
        })
      );
  }

  /**
   * site details
   */
  private siteDetailsBehaviorSubject = new BehaviorSubject<SiteDetail | null>(
    null
  );
  siteDetails$: Observable<SiteDetail | null> =
    this.siteDetailsBehaviorSubject.asObservable();

  private contactBehaviorSubject = new BehaviorSubject<
    GetContactModel[] | null
  >(null);
  contact$: Observable<GetContactModel[] | null> =
    this.contactBehaviorSubject.asObservable();

  public addresValueBehaviorSubject = new BehaviorSubject<any>(null);
  addressValue$: Observable<any> =
    this.addresValueBehaviorSubject.asObservable();

  private setSiteDetailResponseBehaviorSubject =
    new BehaviorSubject<ResponseSiteDetails | null>(null);
  SiteDetailResponse$: Observable<ResponseSiteDetails | null> =
    this.setSiteDetailResponseBehaviorSubject.asObservable();

  /**
   * Reverses geocode
   * @param lat
   * @param lng
   * @returns
   */
  async reverseGeocode(lat: number, lng: number):Promise<google.maps.places.PlaceResult>{
    try {
      this.loadingService.setLoader(true);
      const geocoder = new google.maps.Geocoder();
      const result = await geocoder.geocode({ location: { lat, lng } });
      const resulted= result.results[0]||null
      this.loadingService.setLoader(false);
      return resulted;
    } catch (err) {
       return err as Error;;
    }
  }

  checkDuplicateAddress(
    userId: string,
    enteredAddress: string
  ): Observable<any> {
    let data = {
      enteredAddress: enteredAddress,
    };
    return this.api
      .post(`accounts/${userId}/check-duplicate-address`, data)
      .pipe(
        map((res) => {
          if (res["status"] === 1000) {
            return res["data"];
          }
          return null;
        }),
        catchError((err) => {
          console.error(`error getting address : ${JSON.stringify(err)}`);
          throw err;
        })
      );
  }

  /**
   * Fetchs site details if already saved
   * @param id
   */
  fetchSiteDetails(id: string) {
    this.loadingService.setLoader(true);
    // firstValueFrom(
    return this.api.get(`quote/${id}/site_details`).pipe(
      map((res: GetSiteDetailResponseModel) => {
        //console.log(res);
        if (res["message"] === "Success") {
          // this.siteDetailsBehaviorSubject.next(res["data"] as SiteDetail);
          return res["data"] as SiteDetail;
        } else {
          return null;
          // this.toastr.error("Error fetching site details");
        }
        this.loadingService.setLoader(false);
        return null;
      }),
      catchError((err) => {
        console.error(`error getting site details : ${JSON.stringify(err)}`);
        this.loadingService.setLoader(false);
        throw err;
        // return of(null);
      })
    );
    // );
  }

  /**
   * Saves site details
   * @param data
   */
  saveSiteDetails(data: RequestSiteDetails) {
    return this.api.post("quotes/site-details", data).pipe(
      map((res) => {
        const { data, status, message } = res;
        if (status == 1000 || status == 1018) {
          // this.fetchContacts();
          return res as ResponseSiteDetails;
        } else {
          return null;
          // this.toastr.error("Error while setting site details");
        }
        this.loadingService.setLoader(false);
        return null;
      }),
      catchError((err) => {
        localStorage.removeItem("siteDetailsId");
        console.error(`error getting in site details : ${JSON.stringify(err)}`);
        this.loadingService.setLoader(false);
        throw err;
        // return of(null);
      })
    );
  }

  /**
   * billing details
   */

  private billingDetailsResponseBehaviorSubject =
    new BehaviorSubject<BillingDetailsResponse | null>(null);
  billingDetailsResponse$: Observable<BillingDetailsResponse | null> =
    this.billingDetailsResponseBehaviorSubject.asObservable();

  private fetchBillingDetailsBehaviorSubject =
    new BehaviorSubject<FetchAddressData | null>(null);

  fetchBillingDetails$: Observable<FetchAddressData | null> =
    this.fetchBillingDetailsBehaviorSubject.asObservable();

  /**
   * Saves billing details
   * @param data
   */
  saveBillingDetails(data: BillingDetailsRequest) {
    return this.api.post("quotes/billing-details", data).pipe(
      map((res: ApiRespDTO) => {
        if (res["status"] === 1000 || res["status"] === 1018) {
          // this.billingDetailsResponseBehaviorSubject.next(res);
          return res;
        } else {
          return null;
        }
      }),
      catchError((err) => {
        // this.toastr.error("Error while setting billing details");
        localStorage.removeItem("billingDetailsId");
        console.error(
          `error getting in billing details : ${JSON.stringify(err)}`
        );
        throw err;
      })
    );
  }

  /**
   * Fetchs site details if already saved
   * @param id
   */
  fetchBillingDetails(id: string) {
    this.loadingService.setLoader(true);
    firstValueFrom(
      this.api.get(`quote/${id}/billing_details`).pipe(
        map((res: FetchBillingDetails) => {
          //console.log(res);
          if (res["message"] === "Success") {
            this.fetchBillingDetailsBehaviorSubject.next(res["data"]);
          } else {
            // this.toastr.error("Error fetching billing details");
          }
          // this.loadingService.setLoader(false);
        }),
        catchError((err) => {
          console.error(
            `error getting billing details : ${JSON.stringify(err)}`
          );
          this.loadingService.setLoader(false);
          throw err;
          // return of(null);
        })
      )
    );
  }

  /**
   * Posts payment method to quote
   * @param quoteId
   * @param paymentMethodId
   * @returns
   */
  async postPaymentMethodToQuote(quoteId: string, paymentMethodId: string) {
    this.loadingService.setLoader(true);
    const resp = await firstValueFrom(
      this.api
        .post(`quote/payment-methods`, {
          id: quoteId,
          paymentMethodId: paymentMethodId,
        })
        .pipe(
          catchError((err) => {
            this.loadingService.setLoader(false);
            throw err;
          })
        )
    );
    this.loadingService.setLoader(false);
    return;
  }

  confirmQuote(reqBody: ConfirmQuoteModel) {
    this.loadingService.setLoader(true);
    const resp = this.api.post(`quotes/confirm-quote`, reqBody).pipe(
      map((res: any) => {
        if (res["status"] === 1000 || res["status"] === 1018) {
          if (res["status"] === 1000) {
            const box = this.dialog.open(ConfirmationPopupComponent, {
              minWidth: "30%",
              panelClass: "dialogbox",
              data: {success:true,isInactiveProject:reqBody.projectStatus === "Inactive"},
              disableClose: true,
              // height: "30%",
            });
            box.afterClosed().subscribe((resp) => {
              if (resp) {
                // this.stepperService.stepperBehaviourSubject.next(null)
                // history.pushState(null, document.title, window.location.href);
                this.router.navigateByUrl("/orders");
              }
            });
          }
          return res;
        }
        return null;
      }),
      catchError((err) => {
        const box = this.dialog.open(ConfirmationPopupComponent, {
          minWidth: "30%",
          panelClass: "dialogbox",
          data: false,
          // height: "30%",
        });
        box.afterClosed().subscribe((resp) => {
          if (!resp) {
            this.quoteHasError$.next(false);
          }
        });
        localStorage.removeItem("confirmQuoteId");
        this.loadingService.setLoader(false);
        throw err;
      })
    );

    return resp;
  }

  getQuoteStatus(id: string) {
    this.loadingService.setLoader(true);

    return this.api.get(`quote/${id}/status`).pipe(
      map((res) => {
        if (res["status"] === 200) {
          // this.quoteStatusBehaviourSubject.next(res["data"].currentStatus);
          return res["data"].currentStatus;
        }
        return null;
      }),
      catchError((err) => {
        console.error(`error getting quote satus: ${JSON.stringify(err)}`);
        this.loadingService.setLoader(false);
        throw err;
      })
    );
  }

  // getDocumentId(id: string) {
  //   this.loadingService.setLoader(true);

  //   return this.api.post(`quotes/${id}/document`,{}).pipe(
  //     map((res) => {
  //       if (res["status"] === 200) {
  //         // this.quoteStatusBehaviourSubject.next(res["data"].currentStatus);
  //         return res["data"].currentStatus;
  //       }
  //       return null;
  //     }),
  //     catchError((err) => {
  //       console.error(`error getting quote satus: ${JSON.stringify(err)}`);
  //       this.loadingService.setLoader(false);
  //       throw err;
  //     })
  //   );
  // }

  deleteLocation(data: DeleteLocationReq) {
    this.loadingService.setLoader(true);
    return this.api.delete(`quotes/site-details`, data).pipe(
      map((res) => {
        if (res["status"] === 1000) {
          return res;
        }
        return null;
      }),
      catchError((err) => {
        console.error(`error deleting  location : ${JSON.stringify(err)}`);
        throw err;
      })
    );
  }

  checkForExistingContact(formValues, savedContacts: GetContactModel[]): Observable< DialogResult | null> {
    const existingContact = savedContacts.find(contact =>
      contact.email === formValues.email.toLowerCase()
    );

    if (existingContact) {
      const dataExistingContact:Partial<GetContactModel> = {
        firstName: existingContact.firstName,
        lastName: existingContact.lastName,
        email: existingContact.email,
        phone: existingContact.phone,
        contactId: existingContact.contactId,
      };

      const dialogRef = this.dialog.open(SiteAddressExistPopupComponent, {
        disableClose: true,
        data: 'contact',
      });

      return dialogRef.afterClosed().pipe(
        map(result => ({
          dialogData: result,
          contact: dataExistingContact as GetContactModel
        }))
      );
    } else {
      return of(null);  // Return an observable with null if no existing contact is found
    }
  }
 
}

import { Injectable, signal } from "@angular/core";
import { Router } from "@angular/router";
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
} from "@capacitor/push-notifications";
import { UssApiService } from "../uss-api/uss-api.service";
import { catchError,map } from "rxjs";
import { UserProfile } from "src/app/models/profile-model";
import { FirebaseMessaging, NotificationReceivedEvent } from "@capacitor-firebase/messaging";
import { App } from "@capacitor/app";
import { LocalNotifications } from "@capacitor/local-notifications";
import { Platform } from "@ionic/angular";
@Injectable({
  providedIn: "root",
})
export class PushNotificationService {
  selectedAccountId: string | undefined;
  notificationBellSignal = signal<boolean>(false);

  constructor(
    private router: Router,
    private api: UssApiService,
    private platform: Platform
  ) {}

  // Method to initialize push notifications
  async initPushNotifications(
    selectedAccount: Partial<UserProfile>
  ): Promise<void> {
    try {
      const permStatus = await PushNotifications.checkPermissions();
      if (permStatus.receive === "prompt") {
        const requestPermission = await PushNotifications.requestPermissions();
        if (requestPermission.receive !== "granted") {
          throw new Error("User denied push notification permissions.");
        }
      } else if (permStatus.receive !== "granted") {
        throw new Error("Push notification permission not granted.");
      }

      await this.registerNotifications();
      await this.addListeners(selectedAccount);
    } catch (error) {
    }
  }

  private async registerNotifications(): Promise<void> {
    await PushNotifications.register();
  }

  private async addListeners(
    selectedAccount: Partial<UserProfile>
  ): Promise<void> {
    const tokenResult = await FirebaseMessaging.getToken();
    this.selectedAccountId = selectedAccount.accountId;

    this.sendFcmTokenToServer({
      fcmToken: tokenResult.token,
      ussPortalUserId: selectedAccount.accountId,
      emailId: selectedAccount.email,
    }).subscribe();


    if (this.platform.is("android")) {
      FirebaseMessaging.addListener("notificationReceived", (notification) => {
        this.notificationBellSignal.set(true);
        const objectId = (notification.notification.data as { objectId?: string })
          ?.objectId;

        this.doLocalNotification(notification, objectId);

        LocalNotifications.addListener(
          "localNotificationActionPerformed",
          (notification) => {
            const extraData = notification.notification.extra as {
              route?: string;
              objectId?: string;
            };

            if (extraData && extraData.objectId) {
              this.router.navigate([`cases/case-details/${extraData.objectId}`]);
            }
          }
        );
      });
    }


    // Listener for receiving a notification
    await PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        this.notificationBellSignal.set(true); 
      }
    );

    // Listener for notification action performed
    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      async (notification: ActionPerformed) => {
        const objectId = notification.notification.data?.objectId;
        if (objectId) {
          this.router.navigateByUrl(`cases/case-details/${objectId}`);
        }
      }
    );

    await PushNotifications.addListener("registrationError", (err) => {
      console.log(err);
    });
  }


  sendFcmTokenToServer(data: {
    fcmToken: string;
    ussPortalUserId: string | undefined;
    emailId: string | undefined;
  }) {
    return this.api
      .post(`accounts/${this.selectedAccountId}/update-fcm-token`, data)
      .pipe(
        map((res) => res["data"]),
        catchError((err) => {
          throw err;
        })
      );
  }

  getPushNotifications(selectedAccount: string | undefined) {
    return this.api
      .get(`notifications/${selectedAccount}/get-notifications`)
      .pipe(
        map((res) => res["data"]),
        catchError((err) => {
          throw err;
        })
      );
  }

  resetNotificationFlag() {
    this.notificationBellSignal.set(false);
  }

  private async doLocalNotification(
    notification: NotificationReceivedEvent,
    objectId: string | undefined
  ) {
    const uniqueId = new Date().getTime() % 100000; 
    await LocalNotifications.schedule({
      notifications: [
        {
          title: notification.notification.title || "No Title",
          body: notification.notification.body || "No Body",
          id: uniqueId,
          actionTypeId: "navigate-action",
          extra: {
            route: `cases/case-details/${objectId || ""}`,
            objectId: objectId || "",
          },
          smallIcon: "res://drawable/notification_img",
        },
      ],
    });
  }
}

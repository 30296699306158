import { registerPlugin, WebPlugin } from '@capacitor/core';
const LiveUpdates = registerPlugin('LiveUpdates', {
  web: () => new LiveUpdatesWeb()
});
export const sync = async () => {
  const result = await LiveUpdates.sync();
  if ('failStep' in result) {
    return Promise.reject(result);
  } else {
    return Promise.resolve(result);
  }
};
export const setConfig = async config => {
  return LiveUpdates.setConfig(config);
};
export const getConfig = async () => {
  return LiveUpdates.getConfig();
};
export const resetConfig = async () => {
  return LiveUpdates.resetConfig();
};
export const reload = async () => {
  return LiveUpdates.reload();
};
class LiveUpdatesWeb extends WebPlugin {
  async sync() {
    return {
      failStep: 'CHECK',
      message: 'Not implemented for web only'
    };
  }
  /* eslint-disable */
  //@ts-ignore
  async setConfig(config) {}
  async getConfig() {
    return {};
  }
  async resetConfig() {}
  async reload() {}
}
export * from './definitions';

import { Component, Input } from "@angular/core";
import { MenuItem } from "../menu/menu.model";
import { Router } from "@angular/router";
import { Settings } from "src/app/app.settings.model";
import { AppSettings } from "src/app/app.settings";

@Component({
  selector: "app-mobile-menu-items",
  templateUrl: "./mobile-menu-items.component.html",
  styleUrls: ["./mobile-menu-items.component.scss"],
})
export class MobileMenuItemsComponent {
  @Input() items: MenuItem[] = [];
  public settings: Settings;

  constructor(private router: Router, public appSettings: AppSettings) {
    this.settings = this.appSettings.settings;
  }

  navigate(item: MenuItem) {
    if (item.route) {
      this.router.navigate([item.route]);
    } else if (item.externalLink) {
      window.open(item.externalLink, "_blank");
    }
  }
}

import { Component, ElementRef, HostListener, Input, ViewChild, effect } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Cases } from "src/app/models/case-list-model";
import { FormBuilder } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { CaseDetailsStateService } from "src/app/features/cases/services/case-details-state.service";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { Subscription } from "rxjs";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { AppBarTitleService } from "src/app/features/myuss/services/app-bar-title/app-bar-title.service";
import { EnhanceQuoteService } from "src/app/features/enhance-quotes/services/enhance-quote.service";

@Component({
  selector: "app-case-list",
  templateUrl: "./case-list.component.html",
  styleUrls: ["./case-list.component.scss"],
})
export class CaseListComponent {
  @Input() orderName: string;
  @Input() screen: string;


  dataSource: MatTableDataSource<Cases>;
  //Order related cases column
  displayedColumns: string[] = [
    "caseNumber",
    "createdDate",
    "lastModifiedDate",
    "type",
    "status",
    "actions",
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("searchInput") searchInput: ElementRef;

  currentDate: string;
  endPoint: string;

  isClosedCaseFlag: boolean = false;
  title: string = "Cases";
  isClosedCase: string = "Closed Cases";
  selectedOption: string = "30 days";
  allCases: Cases[];
  isFromDateSelected: boolean = false;
  isEndDateSelected: boolean = false;
  isOrderScreen: boolean = false;

  waitingForSubmitterStatus: boolean = false;
  isCaseClosed: boolean = false; // Initialize with initial state
  showFromToDate: boolean = false;
  selectedDate: Date;
  fromDate: string = "";
  endDate: string = "";
  endDateMin: Date;
  maxDate: Date = new Date();
  minEndDate: Date = new Date();
  accountId: string;
  contractId: string;
  monthStartDate: string;
  isCases: boolean = true;
  isOrderCases: boolean = true;
  casesSubscription: Subscription;
  mobileView: boolean = false;
  // mobile view 
  contractsFilter : string = "Activated,Suspended,Draft";
   type : string = "";
   contactId : string = "";
   projectId: string = "";


 



  constructor(
    public router: Router,
    public fb: FormBuilder,
    private datePipe: DatePipe,
    private caseService: CaseDetailsStateService,
    private profileService: ProfileService,
    private _Activatedroute: ActivatedRoute,
    private appBarService:AppBarTitleService,
    private enhanceQuoteService: EnhanceQuoteService,
  ) {
    this._Activatedroute.queryParams.subscribe((params) => {
      this.projectId = params.projectId;
    })
    effect(() => {
 
      if (window.innerWidth <= 600) {
       
        if (!this.mobileView) {
          this.mobileView = true;
          // this.selectedTabIndex=0;
        }
      } else {
       
        if (this.mobileView) {
          this.mobileView = false;
          // this.selectedTabIndex=0;
        }
      }
    
      // Set maxDate to today's date
      this.maxDate.setDate(this.maxDate.getDate());
      this.accountId = this.profileService.selectedAccount().accountId || "";
      this.isCaseClosed = false;

      const urlSegments = this.router.url;
      if (urlSegments.includes("cases")) {
        this.endPoint = "cases";
      }
      if (this.endPoint === "cases" && this.mobileView == false) {
       
        this.screen = "cases";  
        this.getAllActiveCases();
      }
      if(this.screen == "technical-support-list" && this.mobileView == false){
       

        this.type = "MySiteServices"
        this.projectId = ""
        this.contactId = this.profileService.selectedAccount().contactId as string;
          this.getAllActiveCases();
        

     }
     
      });
  }

  ngOnInit(): void {
    if(this.enhanceQuoteService.enhanceQuoteUiSignal()) {
      this.appBarService.setPageTitle('Cases');  
    }

    if (window.innerWidth <= 600) {
     
      if (!this.mobileView) {
        this.mobileView = true;
        // this.selectedTabIndex=0;
      }
    } else {
     
      if (this.mobileView) {
        this.mobileView = false;
        // this.selectedTabIndex=0;
      }
    }

    // Get the current URL segment
    
    const urlSegments = this.router.url;
    if (urlSegments.includes("cases")) {
      this.endPoint = "cases";
    }

    // if (this.endPoint === "cases" && this.mobileView == false) {
    //   console.log("ng on in it ")

    //   console.log("Cases",this.projectId);
    //   this.getAllActiveCases();
    // }
   
 
  }


  @HostListener("window:resize") onWindowResize(): void {
    if (window.innerWidth <= 600) {
    
      if (!this.mobileView) {
        this.mobileView = true;
        // this.selectedTabIndex=0;
      }
    } else {
     
      if (this.mobileView) {
        this.mobileView = false;
        // this.selectedTabIndex=0;
      }
    }
  }

  getAllCases(id?: string) {
    if (this.endPoint === "cases" || this.screen == "technical-support-list") {
      this.getAllActiveCases();
    } else {
      this.isOrderScreen = true;
      // this.isCases = false;
      // this.isOrderCases = false;
      this.caseService
        .getOrderCases(this.accountId, "open", id!)
        .subscribe((result) => {
          if (result.cases?.length > 0) {
            this.isOrderCases = true;
            this.isCases = false;

            this.allCases = result.cases;

            this.updateDisplayColumns('orders')
            setTimeout(() => {
              this.dataSource = new MatTableDataSource<Cases>(this.allCases);

              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            }, 1000);
          } else {
            this.isOrderCases = false;
            this.isCases = false;
          }
        });
    }
  }

  ngOnChanges() {
    if (this.orderName?.length > 0 && this.mobileView ==  false) {
    
      this.getAllCases(this.orderName);
    }
  }

  getCustomDateClosedCases() {
    if (
      this.isCaseClosed 
      
    ) {
    
      
      if (this.isOrderScreen) {
        // console.log("IsFromdateSelected wow" , this.isFromDateSelected);
        this.caseService
          .getOrderClosedCases(
            this.accountId,
            "closed",
            this.orderName,
            this.fromDate,
            this.endDate
          )
          .subscribe((result) => {
            if (result.cases?.length > 0) {
              this.isOrderCases = true;
              this.isCases = false;
            
              this.allCases = result.cases;

              this.updateDisplayColumns('orders')
              this.dataSource = new MatTableDataSource<Cases>(this.allCases);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            } else {
              this.isOrderCases = false;
              this.isCases = false;
              this.isEndDateSelected = false;
              this.isFromDateSelected = false;
            }
          });
      } else {
        this.searchInput.nativeElement.value = "";
        
      
        this.caseService
          .getClosedCases(this.accountId, "closed", this.fromDate, this.endDate,this.type,
            this.contactId,this.projectId)
          .subscribe((result) => {
            if (result.cases?.length > 0) {
              this.isCases = true;
              this.isOrderCases = false;
              this.isEndDateSelected = false;
              this.isFromDateSelected = false;
              this.allCases = result.cases;

              this.updateDisplayColumns()

              this.dataSource = new MatTableDataSource<Cases>(this.allCases);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            } else {
              this.isCases = false;
              this.isOrderCases = false;
              this.isEndDateSelected = false;
              this.isFromDateSelected = false;
            }
          });
      }
    }
  }

  getAllActiveCases() {
    this.isOrderScreen = false;
   
   

    this.caseService.getCases(this.accountId, "open",this.type,this.contactId,this.projectId).subscribe((result) => {
      if (result.cases?.length > 0) {
        this.isCases = true;
        this.isOrderCases = false;
        this.allCases = result.cases;


        this.updateDisplayColumns()

       
        this.dataSource = new MatTableDataSource<Cases>(this.allCases);
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 0);

        this.router.navigate([], {
          queryParams: {
            projectId: null,
          },
          queryParamsHandling: "merge",
        });

      } else {
        this.isCases = false;
        this.isOrderCases = false;
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.dataSource.filterPredicate = (data: Cases, filter: string) => {
      const dataStr = JSON.stringify(data)?.toLowerCase();
      return dataStr?.indexOf(filter?.toLowerCase()) !== -1;
    };
  }

   

  viewCaseDetails(caseNumber: string) {
    const orderName = this.orderName || "";
    const route: string[] = [];
    // console.log("Screen name:", this.screen);
    
    switch (true) {
      case this.endPoint === "cases":
        route.push("cases/case-details", caseNumber);
        break;
      case this.screen === "technical-support-list":
        route.push("support/technical-support/support-details", caseNumber);
        break;
      default:
        route.push("orders/case-details", caseNumber);
        break;
    }
    this.router.navigate(route);
  }
  

  navigateBack() {
    this.caseService.navigateBack();
  }

  onToggleChange(event: MatSlideToggleChange) {
    this.searchInput.nativeElement.value = "";
    this.isCaseClosed = event.checked;

    if (this.isCaseClosed) {
      this.selectedValue();
    } else {
      this.selectedOption = "30 days"; // or this.selectedOption = '';
      this.isFromDateSelected = false;
      this.isEndDateSelected = false;
      this.showFromToDate = false;

      if (this.isOrderScreen) {
        this.caseService
          .getOrderCases(this.accountId, "open", this.orderName)
          .subscribe((result) => {
            if (result.cases?.length > 0) {
              // console.log("heyy", result);
              this.isOrderCases = true;
              this.isCases = false;

              this.allCases = result.cases;

             
              this.updateDisplayColumns('orders')
              this.dataSource = new MatTableDataSource<Cases>(this.allCases);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            } else {
              this.isOrderCases = false;
              this.isCases = false;
            }
          });
      } else {
        this.getAllActiveCases();
      }

      
    }
  }

  selectedValue() {
    this.searchInput.nativeElement.value = "";
    // console.log("Selected option is", this.selectedOption);
    this.currentDate = this.transformDate(new Date()) ?? "";

    if (this.selectedOption === "Custom Date" && this.isCaseClosed) {
      this.searchInput.nativeElement.value = "";
      this.showFromToDate = true;
    } else if (this.selectedOption === "30 days" && this.isCaseClosed) {
      this.searchInput.nativeElement.value = "";
      this.oneMonth();
    } else if (this.selectedOption === "60 days" && this.isCaseClosed) {
      this.searchInput.nativeElement.value = "";
      this.twoMonth();
    } else if (this.selectedOption === "90 days" && this.isCaseClosed) {
      this.searchInput.nativeElement.value = "";
      this.threeMonth();
    }
  }

  selectedStartDate(event:  MatDatepickerInputEvent<Date>) {
    if(event.value){
      this.fromDate = this.transformDate(event.value) ?? "";
      this.addOneDay(event.value);
      if (this.fromDate) {
        this.isFromDateSelected = true;
      }
    }
  }
  selectedEndDate(event: MatDatepickerInputEvent<Date>) {
    if(event.value){
      this.endDate = this.transformDate(event.value) ?? "";
    }
    
    if (this.endDate) {
      this.isEndDateSelected = true;
    }
  }

  addOneDay(date: Date) {
    const currentDate = new Date();

    if (
      date.getFullYear() !== currentDate.getFullYear() ||
      date.getMonth() !== currentDate.getMonth() ||
      date.getDate() !== currentDate.getDate()
    ) {
      const newDate = new Date(date);

      newDate.setDate(newDate.getDate() + 1);

      this.minEndDate = newDate;
    } else {
      this.minEndDate = new Date();
    }
  }

  calculateCustomDate(count: number) {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - count);
    return currentDate;
  }

  transformDate(date: Date) {
    // console.log("Here is date", date);
    return this.datePipe.transform(date, "yyyy-MM-dd");
  }

  bothDateSelected() {
    if (this.fromDate && this.endDate) {
      // console.log("Both date selected" , this.isFromDateSelected , this.isEndDateSelected , this.fromDate , this.endDate);
      return false; // Enable the button
    } else {
      return true; // Disable the button
    }
  }
  clearSelectedOption() {
    this.selectedOption = ""; // or this.selectedOption = '';
    this.isFromDateSelected = false;
    this.isEndDateSelected = false;
    this.fromDate = "";
    this.endDate = "";
  }

  oneMonth() {
    this.fromDate = "";
    this.endDate = "";
    this.showFromToDate = false;
    this.monthStartDate =
      this.transformDate(this.calculateCustomDate(30)) ?? "";

    if (this.monthStartDate || this.currentDate) {
      if (this.isOrderScreen ) {
        this.caseService
          .getOrderClosedCases(
            this.accountId,
            "closed",
            this.orderName,
            this.monthStartDate,
            this.currentDate,
          )
          .subscribe((result) => {
            if (result.cases?.length > 0) {
              this.isOrderCases = true;
              this.isCases = false;
              this.allCases = result.cases;

              this.updateDisplayColumns('orders')
              this.dataSource = new MatTableDataSource<Cases>(this.allCases);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            } else {
              this.isOrderCases = false;
              this.isCases = false;
            }
          });
      } else {
        this.caseService
          .getClosedCases(
            this.accountId,
            "closed",
            this.monthStartDate,
            this.currentDate,
            this.type,
            this.contactId,
            this.projectId
          )
          .subscribe((result) => {
            // console.log("By defualt one month for closed cases", result);
            this.updateDisplayColumns()
            if (result.cases?.length > 0) {
              this.isCases = true;
              this.isOrderCases = false;
              this.allCases = result.cases;

             
              this.dataSource = new MatTableDataSource<Cases>(this.allCases);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            } else {
              this.isCases = false;
              this.isOrderCases = false;
            }
          });
      }
    }
  }
  twoMonth() {
    this.fromDate = "";
    this.endDate = "";
    this.showFromToDate = false;
    this.monthStartDate =
      this.transformDate(this.calculateCustomDate(60)) ?? "";

    if (this.monthStartDate || this.currentDate) {
      if (this.isOrderScreen) {
        this.caseService
          .getOrderClosedCases(
            this.accountId,
            "closed",
            this.orderName,
            this.monthStartDate,
            this.currentDate
          )
          .subscribe((result) => {
            if (result.cases?.length > 0) {
              this.isOrderCases = true;
              this.isCases = false;
              this.allCases = result.cases;
              this.updateDisplayColumns('orders')

              this.dataSource = new MatTableDataSource<Cases>(this.allCases);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            } else {
              this.isOrderCases = false;
              this.isCases = false;
            }
          });
      } else {
        this.caseService
          .getClosedCases(
            this.accountId,
            "closed",
            this.monthStartDate,
            this.currentDate,
            this.type,
            this.contactId,
            this.projectId

          )
          .subscribe((result) => {
            if (result.cases?.length > 0) {
              this.isCases = true;
              this.isOrderCases = false;
              this.allCases = result.cases;

              this.updateDisplayColumns()

              this.dataSource = new MatTableDataSource<Cases>(this.allCases);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            } else {
              this.isCases = false;
              this.isOrderCases = false;
            }
          });
      }
    }
  }
  threeMonth() {
    this.fromDate = "";
    this.endDate = "";
    this.showFromToDate = false;
    this.monthStartDate =
      this.transformDate(this.calculateCustomDate(90)) ?? "";

    if (this.monthStartDate || this.currentDate) {
      if (this.isOrderScreen) {
        this.caseService
          .getOrderClosedCases(
            this.accountId,
            "closed",
            this.orderName,
            this.monthStartDate,
            this.currentDate
          )
          .subscribe((result) => {
            if (result.cases?.length > 0) {
              this.isOrderCases = true;
              this.isCases = false;
              this.allCases = result.cases;

             this.updateDisplayColumns('orders')
              this.dataSource = new MatTableDataSource<Cases>(this.allCases);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            } else {
              this.isOrderCases = false;
              this.isCases = false;
            }
          });
      } else {
        this.caseService
          .getClosedCases(
            this.accountId,
            "closed",
            this.monthStartDate,
            this.currentDate,
            this.type,
            this.contactId,
            this.projectId
          )
          .subscribe((result) => {
            if (result.cases?.length > 0) {
              this.isCases = true;
              this.isOrderCases = false;
              this.allCases = result.cases;

              this.updateDisplayColumns()
              this.dataSource = new MatTableDataSource<Cases>(this.allCases);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            } else {
              this.isCases = false;
              this.isOrderCases = false;
            }
          });
      }
    }
  }


  updateDisplayColumns(screen?: string){ 

    if(screen == 'orders'){
      this.displayedColumns = [
        "caseNumber",
        "createdDate",
        "lastModifiedDate",
        "type",
        "myusscasetype",
        "status",
        "actions",
      ];
    }else if(this.screen == "technical-support-list"){
      this.displayedColumns = [
        "caseNumber",
        "subject",
        "createdDate",
        "lastModifiedDate",
        "status",
        "actions",
      ];
    }else{
      this.displayedColumns = [
        "caseNumber",
        "orderNumber",
        "siteAddress",
        "createdDate",
        "lastModifiedDate",
        "type",
        "myusscasetype",
        "status",
        "actions",
      ];
      
  }
  }



  getSupport(){
    // this.router.navigate(["technical-support/get-support"]);
    this.router.navigateByUrl('/support/technical-support/get-support');
  }

  getOrderNumber(orderNumber: string | null): string {
    return orderNumber && orderNumber !== 'O-null' ? orderNumber : '-';
  }

  getCaseType(caseType: string | null): string {
    if (caseType === 'MySiteServices') {
      return 'Technical Support';
    }
    return caseType ? caseType : '-';
  }
  
  getOrderSiteAddress(siteAddress: string | null): string {
    return siteAddress ? siteAddress : '-';
  }
}

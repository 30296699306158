<header class="header" [class.over]="showInfoContent">
  <mat-toolbar class="top-toolbar" [class.mat-elevation-z2]="settings.fixedHeader" [ngClass]="{'border-radius-8':isMobile}">

    <div *ngIf="!isMobile" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
      <div fxLayout="row" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutAlign="center center">
          <a href="/home" fxLayout="row" fxLayoutAlign="start center" class="logo" ngClass.xs="mobile"
            (click)="closeSubMenus($event)">
            <img src="../../../../../assets/img/USS-Logo-primary.png"
              alt="Logo of United Site Services. Click to return on home screen." />
          </a>
          <div *ngIf="isMobile" class="account-number-mobile">
            <p class="company-name-mobile" aria-labelledby="This is account name and account number">
              {{ profileService.selectedAccount().accountNumber }}
            </p>
          </div>
        </div>






        <div fxLayout="row" fxLayoutAlign="center center">
          <button fxLayout="center center"
            *ngIf="settings.menu == 'vertical' && isMobile && router.url !== '/account/create'" mat-icon-button
            (click)="collapse()" [@rotate]="showSidenav">
            <img class="reverse" src="../../../../../assets/img/Collapse.svg" [alt]="getAltText()" />
          </button>

          <div id="company" *ngIf="!isMobile">
            <p class="company-name pl-5" aria-labelledby="accountInfo">
              {{ profileService.selectedAccount().accountName }} |
              {{ profileService.selectedAccount().accountNumber }}
            </p>
          </div>
          <!-- The element that provides the label -->
          <p id="accountInfo" class="visually-hidden">
            This is account name and account number company
          </p>
        </div>
      </div>


      <div fxLayout="row" fxLayoutAlign="end center">


        <!-- <div class="mobile">
          <app-messages></app-messages>
        </div> -->
        <app-user-help></app-user-help>
        <div *ngIf="isNative && enableFindUnits">
          <app-find-units-options></app-find-units-options>
        </div>
        <app-user-menu></app-user-menu>
      </div>
    </div>

    <div *ngIf="isMobile" fxLayout="row" fxLayoutAlign="center center" fxFlexFill >

      <div fxLayout="row" fxLayoutAlign="center center" fxFlex="15">
        <button fxLayoutAlign="center center"
          *ngIf="settings.menu == 'vertical' && isMobile && router.url !== '/account/create'" mat-icon-button
          (click)="collapse()">
          <img class="drawer-icon" src="assets/img/icons/drawer_handle.svg" [alt]="getAltText()" />
        </button>
      </div>


      <h1 class="page-title" fxFlex="70" fxLayoutAlign="center center">{{ pageTitle }}</h1>


      <div fxLayout="row" fxLayoutAlign="end center" fxFlex="15">
        <div *ngIf="isPushNotificationActive && isNative" class="mobile">
          <img src="assets/img/icons/notification_bell.svg" alt="Notification Bell" (click)="navigateToPushNotification()">
          <div *ngIf="showRedDot" class="notification-badge"></div>
        </div>
        <app-user-menu></app-user-menu>
      </div>
    </div>



  </mat-toolbar>
</header>
<mat-drawer-container id="mainSection" class="page-wrapper" style="overflow: auto;">
  <mat-drawer #sidenav *ngIf="settings.menu == 'vertical' && !isActiveStatus" [opened]="settings.sidenavIsOpened"
    [mode]="settings.sidenavIsPinned ? 'side' : 'over'" (openedStart)="showSidenav = !showSidenav"
    (closedStart)="showSidenav = !showSidenav" class="sidenav" autoFocus="false">
    <mat-toolbar *ngIf="settings.fixedSidenav" class="top-toolbar">
      <a routerLink="/" fxLayout="row" fxLayoutAlign="start center" class="logo">
      </a>
    </mat-toolbar>
    <app-sidenav>
    </app-sidenav>
  </mat-drawer>

  <mat-drawer-content #mainContent class="main-content" (scroll)="onMainContentScroll($event)" style="overflow: auto;">
    <div class="main-content-inner" style="overflow: hidden;">
      <div class="email-verification-ribbon" *ngIf="showEmailVerificationMessage()">
        Please confirm your email address to complete registration.
        <a (click)="requestEmailConfirmation()">Click here</a> to request a new
        confirmation email.
      </div>
      <div *ngIf="!isProduction" class="environment-ribbon"
        [ngClass]="{ 'dev': environmentName === 'Development', 'qa': environmentName === 'Testing', 'stage': environmentName === 'Staging' }">
        Environment - {{environmentName }}
      </div>
      <!-- <app-breadcrumb *ngIf="!isNative && !isMobile" ></app-breadcrumb> -->
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="!isNative" #backToTop fxLayout="row" fxLayoutAlign="center center" class="back-to-top"
      (click)="scrollToTop()">
      <mat-icon>arrow_upward</mat-icon>
    </div>
    <div *ngIf="!showSidenav" >
      <div *ngIf="(isMobileView || isNative) && isShowFooter" color="primary" class="footer footer-banner" [class.full-width]="!showSidenav" 
      fxLayout="row" fxLayoutAlign="space-around center">
      <p>
        Need Help?
      </p>
      <p>
       {{phoneNumber}}      
     </p>
    </div>
    </div>
    <div *ngIf="!isMobileView">
      <mat-toolbar color="primary" class="footer" [class.full-width]="!showSidenav" *ngIf="!isNative">
        <div *ngIf="!isNative">© {{copyrightDate}} United Site Services Inc. All rights reserved.</div>
      </mat-toolbar>
    </div> 
  </mat-drawer-content>
</mat-drawer-container>
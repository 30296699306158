import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable, catchError, map, throwError } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { size } from "lodash";
class HttpError {
  static BadRequest = 400;
  static Unauthorized = 401;
  static Forbidden = 403;
  static NotFound = 404;
  static TimeOut = 408;
  static Conflict = 409;
  static InternalServerError = 500;
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService // Toastr service for alert message
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        // console.log(event["body"]);
        if (this.errorCondition(event)) {
          this.toastr.error(event["body"]?.message);
          // return event;
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        // console.log(error.error);
        const errorMessage = this.setErrorMessage(error);
        this.toastr.error(errorMessage);
        throw error;
      })
    );
  }

  setErrorMessage(error: HttpErrorResponse): string {
    let errorMessage = "Something went wrong";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
      console.log("client-side error");
    } else {
      // server-side error
      if (error.status === 0) {
        errorMessage = "Unable to Connect to the Server";
        return errorMessage;
      }
      errorMessage = `${error.error.message}`;
      console.log("server-side error");
    }
    return errorMessage;
  }

  errorCondition(event: HttpEvent<any>): boolean {
    const successCodes = [1000, 200, 201, 1031, 1018];
    const status = event["body"]?.status || event["status"];
    const isError =
      event["body"] &&
      !successCodes.includes(status) &&
      event["body"]?.type !== "application/pdf";

    return isError;
  }
}

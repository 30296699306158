<div class="popup-header p-2 px-4">
  <div>
    <div *ngIf="isEdit" class="Info-icon-set">
      <h2 >Add Location</h2>
      <mat-icon color="primary" class="error_icon mat_info_size"
      #toolTipForAddLocation="matTooltip"
        matTooltip="Add a new location within the jobsite where you would like to have one or more units placed. This should be within the same general vicinity of the order (i.e. not across town)."
        aria-label="Button that displays a toolTip For AddLocation when focused or hovered over" (click)="toolTipForAddLocation.toggle()">
        info</mat-icon>
      </div>
    <h2 *ngIf="!isEdit">Location</h2>
  </div>
  <div>
    <div *ngIf="isEdit"  class="cursor-pointer"><mat-icon (click)="cancelPopup()" >close</mat-icon></div>
    <div *ngIf="!isEdit" class="icon-wrapper">
      <mat-icon (click)="deletePopup()" class="delete-icon cursor-pointer mx-1"
        >delete</mat-icon
      >
      <mat-icon (click)="editPopup()" class="cursor-pointer edit"
        >edit</mat-icon
      >
    </div>
  </div>
</div>

<div>
  <mat-divider></mat-divider>
</div>

<div class="pt-3 pr-4 pl-4" *ngIf="!isEdit">
  <div fxLayout="row wrap">
    <div fxFlex="100" class="pb-2">
      <div>
        <label>Site Name</label>
      </div>
      <div class="class-show-location">
        {{ locationData?.subSiteDetails?.address?.siteName }}
      </div>
    </div>

    <div fxFlex="40" fxFlex.xs="100" class="pb-2">
      <div>
        <label>Placement Notes</label>
      </div>
      <div class="class-show-location">
        {{ locationData?.subSiteDetails?.address?.instructions }}
      </div>
    </div>

    <div fxFlex="60" fxFlex.xs="100" class="pb-2">
      <div>
        <label>Site Hours</label>
      </div>
      <div class="class-show-location">
        {{ startTime }} -
        {{ endTime }}
      </div>
    </div>
  </div>

  <div fxLayout="row wrap">
    <div fxFlex="40" fxFlex.xs="100" class="pb-2">
      <div>
        <label>Site Contact</label>
      </div>
      <div class="class-show-location">
        {{ locationData?.subSiteDetails?.contact?.firstName }}
        {{ locationData?.subSiteDetails?.contact?.lastName }}
      </div>
    </div>

    <div fxFlex="40" fxFlex.xs="100" class="pb-2">
      <div>
        <label> E-mail </label>
      </div>
      <div class="class-show-location">
        {{ locationData?.subSiteDetails?.contact?.email }}
      </div>
    </div>

    <div fxFlex="20" fxFlex.xs="100" class="pb-2">
      <div>
        <label>Phone Number</label>
      </div>
      <div class="class-show-location">
        {{ locationData?.subSiteDetails?.contact?.phone }}
      </div>
    </div>
  </div>
</div>

<div class="pt-3 pr-4 pl-4" *ngIf="isEdit">
  <div class="w-100">
    <form [formGroup]="addLocationForm" id="addLocationId" fxLayout="row wrap">
      <div class="site-name-main-container pr-2 " fxFlex.xs="100"  fxFlex="50">
        <div class="Info-icon-set">
          <label class="mb-2">Site Name<span class="required">*</span></label><mat-icon color="primary" class="error_icon mat_info_size mb-2"
          #toolTipForSiteName="matTooltip"
            matTooltip="A short, descriptive name for the site that is easy to refer to. Examples: “East Gate” or “Lot 12”."
            aria-label="Button that displays a toolTip For SiteName when focused or hovered over" (click)="toolTipForSiteName.toggle()">
            info</mat-icon>
          </div>
          <mat-form-field class="w-100" appearance="outline" >
            <mat-label> Site Name</mat-label>
            <input matInput placeholder="Site Name" formControlName="siteName" />
            <mat-error *ngIf="addLocationForm.controls.siteName.errors?.required"
              >Site name is required.</mat-error
            >
          </mat-form-field>
      </div>
      <div class="site-upload-container" fxFlex.xs="100" fxFlex="50">
        <span class="headers pl-2" fxFlex="50">Upload Map Attachment</span>
        <app-browse-preview [attchment]='attachments' ></app-browse-preview>
      </div>
      
      <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
        <div class="site_name_div">
          <div class="Info-icon-set">
          <label for="siteName">Placement Note<span class="required">*</span></label><mat-icon color="primary" class="error_icon mat_info_size "
          #toolTipForPlacementNote="matTooltip"
            matTooltip="Specific instructions for the delivery technician when placing units.  Examples: “Inside the gate to the left” or “Near the curb to the right of the driveway” or “Please call upon delivery for instructions”."
            aria-label="Button that displays a toolTip For PlacementNote when focused or hovered over" (click)="toolTipForPlacementNote.toggle()">
            info</mat-icon>
          </div>
          <div fxLayoutAlign="space-between center">
            
          </div>
        </div>
        <mat-form-field class="pt-2" appearance="outline">
          <mat-label> Placement Note</mat-label>
          <input
            matInput
            placeholder="Placement Notes"
            formControlName="placementNotes"
          />
          <mat-error
            *ngIf="addLocationForm.controls.placementNotes.errors?.required"
            >Placement note is required.</mat-error
          >
        </mat-form-field>
      </div>
      <!-- for mobile only -->
      <div *appHideIfMediaQuery="'(max-width: 767px)'" class="site_name_div " fxFlex="100">
        <div  class="check_box">
          <input
           
            type="checkbox"
            (click)="sameAsMainSite()"
            [checked]="isSameAsMainSite"
            class="mr-1 mb-2"
          />
          <label class="mb-2"> Same as site details</label><br />
        </div>
      </div>

      <div fxLayout="column" fxFlex="24.5" class="site_hour_div" fxFlex.xs="50">
        <div class="site_name_div">
          
          <div class="Info-icon-set">
            <label class="site-hour-title" >Site Hours</label><mat-icon color="primary" class="error_icon mat_info_size"
            #toolTipForSiteHours="matTooltip"
              matTooltip="If there are specific time constraints required for servicing, please provide them here.  If not, leave blank."
              aria-label="Button that displays a toolTip For Site Hours when focused or hovered over" (click)="toolTipForSiteHours.toggle()">
              info</mat-icon>
            </div>
        </div>
        <mat-form-field class="start_time" appearance="outline">
          <mat-label> Start Time</mat-label>
          <input
            (change)="siteStartTime($event)"
            matInput
            placeholder="Site Time"
            type="time"
            min="00:00"
            max="23:59"
            formControlName="siteHoursStart"
          />
        </mat-form-field>
      </div>
      
      <div fxLayout="column" fxFlex="25" fxFlex.xs="50">
       
        <div *appHideIfMediaQuery="'(min-width: 768px)'" class="site_name_div">
          <div  class="check_box">
            <input
             
              type="checkbox"
              (click)="sameAsMainSite()"
              [checked]="isSameAsMainSite"
              class="mr-1 mb-2"
            />
            <label class="mb-2"> Same as site details</label><br />
          </div>
        </div>
        <mat-form-field class="class-t-end-time" appearance="outline">
          <mat-label> End Time</mat-label>
          <input
            (change)="siteEndTime($event)"
            matInput
            placeholder="End Time"
            type="time"
            min="00:00"
            max="23:59"
            formControlName="siteHoursEnd"
          />
        </mat-form-field>
      </div>
      <!-- for mobile view only -->
      <div *appHideIfMediaQuery="'(max-width: 767px)'" class="bottom-info">
        <div fxLayoutAlign="space-between center">
          <div class="menu-list">
            <div
              [matMenuTriggerFor]="menu"
              #menuClick="matMenuTrigger"
              fxLayoutAlign="center center"
            >
              <p>Choose from saved contacts</p>
              <mat-icon>{{
                menuClick.menuOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"
              }}</mat-icon>
            </div>
            <mat-menu class="menu-option" #menu="matMenu" xPosition="before">
              <p
                class="px-3 py-2"
                *ngFor="let contact of savedContacts"
                (click)="selectFromSavedContacts(contact)"
              >
                {{ contact.firstName }} {{ contact.lastName }},
                {{ contact.email }},
                {{ contact.phone }}
              </p>
              <p class="px-3 py-2" *ngIf="savedContacts.length < 1">
                No data available
              </p>
            </mat-menu>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxLayoutAlign="space-between center">
        
        <div class="Info-icon-set">
          <label class="mb-2" for="placementNotes">Site Contact<span class="required">*</span></label><mat-icon color="primary" class="error_icon mat_info_size mb-2"
          #toolTipForSiteContact="matTooltip"
            matTooltip="The person to contact if there are any questions or issues.  Ideally, this person will be onsite regularly."
            aria-label="Button that displays a toolTip For SiteContact when focused or hovered over" (click)="toolTipForSiteContact.toggle()">
            info</mat-icon>
          </div>
        <!-- Choose from saved contacts -->
      <div *appHideIfMediaQuery="'(min-width: 768px)'" class="bottom-info">
        <div fxLayoutAlign="space-between center">
          <div class="menu-list">
            <div
              [matMenuTriggerFor]="menu"
              #menuClick="matMenuTrigger"
              fxLayoutAlign="center center"
            >
              <p>Choose from saved contacts</p>
              <mat-icon>{{
                menuClick.menuOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"
              }}</mat-icon>
            </div>
            <mat-menu class="menu-option" #menu="matMenu" xPosition="before">
              <p
                class="px-3 py-2"
                *ngFor="let contact of savedContacts"
                (click)="selectFromSavedContacts(contact)"
              >
                {{ contact.firstName }} {{ contact.lastName }},
                {{ contact.email }},
                {{ contact.phone }}
              </p>
              <p class="px-3 py-2" *ngIf="savedContacts.length < 1">
                No data available
              </p>
            </mat-menu>
          </div>
        </div>
      </div>
  
      </div>

     
      <mat-form-field
        fxFlex="25"
        fxFlex.sm="50"
        fxFlex.xs="100"
        appearance="outline"
      >
        <mat-label> First Name</mat-label>

        <input matInput placeholder="First Name" formControlName="firstName" />
        <mat-error *ngIf="addLocationForm.controls.firstName.errors?.required"
          >First Name is required.</mat-error
        >
      </mat-form-field>

      <mat-form-field
        class="site_contact_last_name_phone_number"
        fxFlex="25"
        fxFlex.sm="50"
        fxFlex.xs="100"
        appearance="outline"
      >
        <mat-label> Last Name</mat-label>

        <input matInput placeholder="Last Name" formControlName="lastName" />
        <mat-error *ngIf="addLocationForm.controls.lastName.errors?.required"
          >Last Name is required.</mat-error
        >
      </mat-form-field>
      <mat-form-field
        class="site_contact_email"
        fxFlex="25"
        fxFlex.sm="50"
        fxFlex.xs="100"
        appearance="outline"
      >
        <mat-label> E -mail </mat-label>

        <input matInput placeholder="E -mail" formControlName="email" />
        <mat-error *ngIf="addLocationForm.controls.email.errors?.required"
          >Email is required.</mat-error
        >
        <mat-error
          *ngIf="
            addLocationForm.controls.email.errors?.email ||
            addLocationForm.controls.email.errors?.pattern
          "
          >Please enter a valid email.</mat-error
        >
      </mat-form-field>
      <mat-form-field
        class="site_contact_last_name_phone_number"
        fxFlex="25"
        fxFlex.sm="50"
        fxFlex.xs="100"
        appearance="outline"
      >
        <mat-label> Phone Number</mat-label>

        <input
          matInput
          #phoneInput
          id="phone"
          (keyup)="onkey(phoneInput.value)"
          type="text"
          [maxLength]="10"
          placeholder="Phone Number"
          formControlName="phone"
        />
        <mat-error *ngIf="addLocationForm.controls.phone.errors?.required"
          >Phone Number is required.</mat-error
        >
        <mat-error *ngIf="addLocationForm.controls.phone.hasError('minlength')"
          >Please enter 10-digit Phone Number.</mat-error
        >
      </mat-form-field>
    </form>
  </div>
</div>

<div class="py-2">
  <mat-divider></mat-divider>
</div>

<div *ngIf="locationData?.type != 'view'" class="button-row pb-2 px-2">
  <button mat-stroked-button (click)="cancelPopup()" mat-dialog-close>
    Cancel
  </button>
  <button
    mat-raised-button
    color="primary"
    *ngIf="!addLocationForm.value.id"
    (click)="createLocation()"
  >
    Create
  </button>
  <button
    mat-raised-button
    color="primary"
    *ngIf="addLocationForm.value.id"
    (click)="onSave()"
  >
    Save
  </button>
</div>

<div *ngIf="locationData?.type === 'view'" class="pb-2 px-2">
  <div class="button-row">
    <button mat-stroked-button (click)="cancelPopup()" mat-dialog-close>
      Cancel
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!isEdit"
      (click)="onSave()"
    >
      Save
    </button>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetpopupComponent } from '../shared/components/dialogbox/resetpopup/resetpopup.component';
import { VerticalMenuComponent } from './components/vertical-menu/vertical-menu.component';
import { MessagesComponent } from './components/messages/messages.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { MobileMenuItemsComponent } from './components/mobile-menu-items/mobile-menu-items.component';
import { ToolTipTemplateComponent } from "../shared/components/tool-tip-template/tool-tip-template.component";



@NgModule({
  declarations: [    
    SidenavComponent,
    VerticalMenuComponent,
    ResetpopupComponent,
    MessagesComponent,
    MobileMenuItemsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    ToolTipTemplateComponent
],
  exports: [
    SidenavComponent,
    VerticalMenuComponent,
    ResetpopupComponent,
    MessagesComponent,
  ]
})
export class ThemeModule { }

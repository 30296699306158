import { Document } from "./case-details-model";
import { Contact } from "./quoteState-model";

export class Address {
  accountId: string;
  siteName?: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  latitude: number|undefined;
  longitude: number|undefined;
  shipToAddress?: boolean;
  addressId?: string;
  addressExist?: boolean;

  id?: string;
  name?: string;
  address?: string;
  zipCode?: string;
  addressValidated?: boolean;
  geocodeAccuracy?: string;
  siteContact?: Contact;
  secondarySiteContact?: Contact;
  shipToContact?: Contact;
  isShippingAddress?: boolean;
  isBillingAddress?: boolean;
  isParentAddress?: boolean;
  placementNotes?: string;
  parentRefId?: string;
  idRequired?: boolean;
  gateCode?: string| null;
  startTime?: string| null;
  endTime?: string| null;
}

export class GetAddressModel {
  addressId: string;
  account: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  latitude: number;
  longitude: number;
  isBillingAddress: boolean;
  isParentAddress: boolean;
  siteName?: string;
  serviceable?:boolean
  country?: string;
}

export class DeleteLocationReq {
  requestId: string;
  addressId: string;
  quoteId: string;
}

export class SubSiteAddress extends Address {
  override startTime: string;
  override endTime: string;
  instructions: string;
  override gateCode?: string;
  information?: string;
  override idRequired?: boolean;
  clearanceRequired?: boolean;
  fileId?:string;
}

export class AddLocation {
  id: string;
  contactId: string;
  contactExist: boolean;
  address: SubSiteAddress;
  attachments?: Document[];
  contact: Contact;
}

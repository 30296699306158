import { PushNotificationService } from 'src/app/features/myuss/services/push-notification/push-notification.service';
import { Platform } from '@ionic/angular';
import { Injectable, signal } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { LoadingService } from "../../../../shared/services/loading/loading.service";
import { UssApiService } from "../../../myuss/services/uss-api/uss-api.service";
// import { AuthService } from "@auth0/auth0-angular";
import {
  BehaviorSubject,
  catchError,
  firstValueFrom,
  map,
  of,
  take,
} from "rxjs";
import {
  UpdateProfileResponse,
  User,
  UserProfileUpdate,
  UserProfile,
} from "src/app/models/profile-model";
import { HttpBackend, HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { RfqService } from "../../../landing/services/rfq/rfq.service";
import { ApiRespDTO } from "src/app/models/api.model";
import { partial } from "lodash";
import { AuthenticationService } from "src/app/shared/services/authentication/authentication.service";
import { InitialUrlService } from "src/app/shared/services/initial-url/initial-url.service";
import { MatDialog } from '@angular/material/dialog';
import { TrackingPopupComponent } from 'src/app/features/tracking-popup/tracking-popup/tracking-popup.component';
import { SignupModel } from 'src/app/models/signup';

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  private readonly picklist =
    "assets/config/business-and-customer-picklist.json";
  private _handler: HttpBackend;
  public userProfile$ = new BehaviorSubject<UserProfile>(new UserProfile());
  public initialApplicationStateLoaded = true;
  private isMapView: boolean = false;

  public selectedAccount = signal({} as Partial<UserProfile>);

  constructor(
    private api: UssApiService,
    private loadingService: LoadingService,
    private toastr: ToastrService,
    // public auth: AuthService,
    private handler: HttpBackend,
    public router: Router,
    private rfqService: RfqService,
    private auth: AuthenticationService,
    private initialUrlService: InitialUrlService,
    private platform: Platform,
    private dialog: MatDialog,
    private pushNotificationService: PushNotificationService
  ) {
    this._handler = handler;
  }

  public async requestEmailConfirmation(): Promise<boolean> {
    return firstValueFrom(
      this.api.post("users/me/request-email-verification", {}).pipe(
        take(1),
        map((res: ApiRespDTO) => {
          if (res["status"] === 1000) {
            return true;
          }
          return false;
        }),
        catchError((err) => {
          console.error(`requestEmailConfirmation ${JSON.stringify(err)}`);
          throw err;
        })
      )
    );
  }

  public async userIsRegistered() {
    const accountId = this.selectedAccount()?.accountId;

    const isRegistered = accountId !== undefined && accountId !== null;
    return isRegistered;
  }

  /**
   * Fetchs profile api
   */
  fetchProfile(fetchingInitialApplicationState = this.initialApplicationStateLoaded) {
    // TODO: leverage locally stored profile if available and current
    // if (localStorage.getItem("userProfile")) {
    //   const userProfile = JSON.parse(
    //     localStorage.getItem("userProfile") as string
    //   );
    //   this.userProfile$.next(userProfile);
    //   return of(userProfile);
    // }
    return this.api.get("users/me").pipe(
      take(1),
      map((res: ApiRespDTO) => {
        if (res["status"] === 1000) {
          if(fetchingInitialApplicationState){
            localStorage.removeItem("initialUrl")
          }
          const userProfile = res["data"] as UserProfile;
          this.userProfile$.next(userProfile);
          localStorage.setItem("userProfile", JSON.stringify(userProfile));
          const selectedAccount = this.recentlyUsedAccount(
            userProfile.accounts
          );
          
          
          this.selectedAccount.set(selectedAccount);

          if(this.platform.is("hybrid") && userProfile.isPushNotificationActive){
            this.pushNotificationService.initPushNotifications(this.selectedAccount());
          }

          return res["data"];
        }
        else if(res["status"] === 1031){
          const userProfile = res["data"] as UserProfile;
          this.userProfile$.next(userProfile);

        }


        return null;
      }),
      catchError((err) => {
        console.error(`error user profile: ${JSON.stringify(err)}`);
        throw err;
      })
    );
  }

  /**
   * Updates profile
   * @param data
   * @returns
   */
  updateProfile(data: UserProfileUpdate) {
    return this.api.post(`users/update-profile`, data).pipe(
      map((res: UpdateProfileResponse) => {
        if (res["status"] === 1000) {
          this.toastr.success("Profile updated successfully.");
          return res;
        } else {
          return null;
        }
      }),
      catchError((err) => {
        console.error(`error getting updating profile: ${JSON.stringify(err)}`);
        this.loadingService.setLoader(false);
        return of(null);
      })
    );
  }
  /**
   * Resets password
   * @param data
   * @returns
   */
  resetPassword(data) {
    return this.api.post(`users/change-password`, data).pipe(
      map((res) => {
        if (res["status"] === 1000) {
          return res;
        } else {
          return null;
        }
      }),
      catchError((err) => {
        console.error(`error getting updating profile: ${JSON.stringify(err)}`);
        return of(null);
      })
    );
  }
  /**
   * Signs up
   * @param reqBody
   * @returns
   */
  signUp(reqBody:SignupModel) {
    return this.api.post("users/signup", reqBody).pipe(
      map((res) => {
        if (res["status"] === 1000 || res["status"] === 1018) {
          return res;
        }
        return null;
      }),
      catchError((err) => {
        console.error(`error in signUp: ${JSON.stringify(err)}`);
        throw err;
      })
    );
  }
  /**
   * Gets business and customer picklist
   * @returns
   */
  async getBusinessAndCustomerPicklist() {
    const client = new HttpClient(this._handler);
    const picklist = await firstValueFrom(client.get<string[]>(this.picklist));
    return picklist;
  }

  public async getInitialApplicationState(initialApplicationStateLoaded=this.initialApplicationStateLoaded) {

    if (initialApplicationStateLoaded && this.userProfile$.value?.id) {
      return;
    }

    const profile = await firstValueFrom(this.fetchProfile(initialApplicationStateLoaded));
    localStorage.setItem("userProfile", JSON.stringify(profile));
    if (profile && profile.accountId) {
      // if the profile exists store properties and navigate
      const email = profile.email as string;
      localStorage.setItem("firstName", profile.firstName);
      localStorage.setItem("userId", profile.accountId);
      localStorage.setItem("accountNumber", profile.accountNumber);
      localStorage.setItem("accountName", profile.accountName);
      localStorage.setItem("email", email);
      // get RFQ details and navigate as appropriate
      const rfq = await this.rfqService.getRFQDetailsAsync();
      // if the email address matches the rfq email address navigate to site address validation
      if (rfq && rfq.emailAddress === email) {
        if (localStorage.getItem('enhanceQuoteUiFlag') === "true") {
          this.router.navigateByUrl("quotes/v2/quote-creation/new_quote");
        }
        else{
          this.router.navigateByUrl("/quotes/siteaddressvalidation");
        }
      } else if (localStorage.getItem("easypay") === "true") {
        // if easypay is true will navigate to easy pay
        this.router.navigateByUrl("/easypay");
      } else {
        if(this.initialUrlService.getInitialUrl() &&  this.initialUrlService.getInitialUrl()!=="/"){
          const url= this.initialUrlService.getInitialUrl()?.split("?")[0];
          this.router.navigateByUrl(url);
          localStorage.removeItem("initialUrl")
          }else{
          // otherwise navigate to home
          this.router.navigateByUrl("/home");
        }
      }
    } else {
      // navigates to createaccount
      this.router.navigateByUrl("/account/create");
      localStorage.removeItem("registred");
    }
    this.initialApplicationStateLoaded = true;
  }

  async getUserProfileValue(key: string): Promise<boolean|string|undefined>{
    // const isAuthenticated = await firstValueFrom(this.auth.isAuthenticated$);
    // if (!isAuthenticated) {
    //   return true;
    // }
    if (!this.userProfile$.value?.accountId) {
      await firstValueFrom(this.fetchProfile());
    }

    const user = this.selectedAccount();
    const isRegistered =
      user?.accountId !== undefined && user?.accountId !== null;

    if (key === "myussEnabled" && !isRegistered) {
      return true;
    }
    if(this.userProfile$.value.enforceRBAC){
      if(user.myussUserRole === null && !localStorage.getItem("signUpRequestId")){
        this.router.navigateByUrl("/restricted-access");
      }
    }

    const flagsArray = [
      user.myussModules?.myussQuotesEnabled,
      user.myussModules?.myussHomeEnabled,
      user.myussModules?.myussEasyPayEnabled,
      user.myussModules?.myussBillingEnabled,
      user.myussModules?.myussOrdersEnabled,
      user.myussModules?.myussCasesEnabled,
      user.myussModules?.myussProjectsEnabled,
    ];
    const allDisable = flagsArray.every((flag) => flag === false);
    if ((!user.myussModules?.myussEnabled && isRegistered && !localStorage.getItem("signUpRequestId"))) {
      console.log("in this block for isRegistered");

      this.router.navigateByUrl("/restricted-access");
    }
    if (allDisable && user.myussModules?.myussEnabled && user.myussUserRole &&!localStorage.getItem("signUpRequestId")) {
      console.log("in this block for myussEnabled");

      this.router.navigateByUrl("/restricted-access?modules=disabled")  ;
    }
   
    const value = this.selectedAccount().myussModules?.[key];
    console.log(`key: ${key} value: ${value}`);
    return value;
  }

  async getAllMyussFlags() {
    let sysadmin = false;

    if (!this.userProfile$.value?.accountId) {
      await firstValueFrom(this.fetchProfile());
    }
    let token = await this.auth.getAccessToken();
    if(token){
      const payload = JSON.parse(atob(token.split(".")[1]));
      if (payload["permissions"].includes("all:redis:sysadmin")) {
        sysadmin = true;
      }
    }
    const user = this.selectedAccount();

    const flags:Record<string,boolean> = {
      ...user.myussModules,
      sysadmin: sysadmin,
      myussUsersEnabled:(this.userProfile$.value.enforceRBAC) && (user.myussUserRole === 'Account Admin' || user.myussUserRole === 'Account Owner')
    };
    return flags;
  }
  
  recentlyUsedAccount(accounts: Partial<UserProfile>[]): Partial<UserProfile> {
    const recentlyUsedAccountId = localStorage.getItem("accountId");
    if (!recentlyUsedAccountId) {
      localStorage.setItem("accountId", `${accounts[0].accountId}`); 
      return accounts[0];
    }
    const recentlyUsed = accounts?.filter(
      (account) => account.accountId === recentlyUsedAccountId
    )[0];
    if (recentlyUsed?.accountId) {
      localStorage.setItem("accountId", `${recentlyUsed.accountId}`);
    } else {
      localStorage.setItem("accountId", `${accounts[0]?.accountId}`);
      return accounts[0];
    }
    return recentlyUsed;
  }

  set setSelectedAccount(account: Partial<UserProfile>) {
    if (this.selectedAccount().accountId === account.accountId) {
      return;
    }
    // if the user changes the account  on the easypay or quote creation page, redirect to home
    if (
      this.router.url.includes("easypay") ||
      this.router.url.includes("quotes/quotecreation") ||
      this.router.url.includes("orders/ordersummary") || this.router.url.includes("orders/edit-order") ||
      this.router.url.includes("cases/case-details") ||
      this.router.url.includes("users/user-details") || this.router.url.includes("users/add-user") || this.router.url.includes("users/edit-user") ||
      this.router.url.includes("projects/project-details") || this.router.url.includes("projects/add-project") || this.router.url.includes("projects/edit-project")
    ) {
      this.router.navigateByUrl("/home");
    }
    this.selectedAccount.set(account);
  }

  isAccountInActive() {
    return this.selectedAccount().accountPaymentStatus === "INACTIVE"|| this.selectedAccount().accountPaymentStatus === "CLOSED";
  }
  isReadOnly() {
    return this.selectedAccount().myussUserRole === "Read Only";
  }


  setMapView(value: boolean): void {
    this.isMapView = value;
  }

  getMapView(): boolean {
    return this.isMapView;
  }
  async showTrackingPopup() {
    this.dialog
      .open(TrackingPopupComponent, { disableClose: true })
      .afterClosed()
      .subscribe(() => {
        localStorage.setItem("trackingPopup", "true");
      });
  }

  isRestrictedAccess() {
    if (!this.selectedAccount().myussModules?.myussEnabled) {
      return true;
    }
    const user:Record<string,boolean> ={
      ...this.selectedAccount().myussModules ,
      myussEnabled:false
    };

    const flagsArray = Object.values(user)
    const test1=flagsArray.every((flag: Boolean) => flag === false);

    return  test1
  }

}

import { Component } from "@angular/core";
import { HelperFunctionService } from "src/app/theme/utils/helper-function.service";
@Component({
  selector: "app-navigate-back",
  standalone: true,
  // imports: [],
  templateUrl: "./navigate-back.component.html",
  styleUrl: "./navigate-back.component.scss",
})
export class NavigateBackComponent {
  constructor(public helperFunctions: HelperFunctionService) {}
  isMobileView: Boolean = false;

  ngOnInit(): void{
    this.detectMobileOrWebView();
    console.log("IS MOBILE...",this.isMobileView)
  }

  navigateBack() {
    this.helperFunctions.handleNavigateBack();
  }

  detectMobileOrWebView() {
    const mobileQuery = "(max-width: 767px)";
    const webQuery = "(min-width: 768px)";

    // Detect mobile view with a dynamic media query
    this.helperFunctions.isViewportMatch(mobileQuery).subscribe((result) => {
      if (result.matches) {
        this.isMobileView = true;
      }
    });

    // Detect web view with a dynamic media query
    this.helperFunctions.isViewportMatch(webQuery).subscribe((result) => {
      if (result.matches) {
        this.isMobileView = false;
      }
    });
  }
}

<section class="p-2">
  <div *ngIf="notificationList?.length === 0" class="no-notifications">
    <p>No notifications available</p>
  </div>
  <div
    *ngFor="let notification of notificationList"
    class="notification-card"
    (click)="navigateTo(notification)"
  >
    <div class="notification-item">
      <div class="icon">
        <mat-icon color="primary">
          {{ notificationConfig[notification.notificationType]?.icon || 'info' }}
        </mat-icon>
      </div>
      <div class="notification-content">
        <span class="title">
          {{ notificationConfig[notification.notificationType]?.title || 'Notification' }}
        </span>
        <p>{{ notification.notificationText }}</p>
        <span class="time">{{ notification.createdAt | date: 'MM/dd/yyyy'}}</span>
      </div>
    </div>
  </div>
</section>

import { Injectable } from "@angular/core";


@Injectable({
  providedIn: "root",
})
export class InitialUrlService {
  constructor() {}

  private initialUrl: string;

  async setInitialUrl(url: string): Promise<void> {
    localStorage.getItem("initialUrl") == "/"
      ? localStorage.removeItem("initialUrl")
      : null;
    if (!localStorage.getItem("initialUrl")&& !url.includes("login-callback")&& !url.includes("rfq-redirect")) {
      localStorage.setItem("initialUrl", url);
      this.initialUrl = url;
    } else {
      this.initialUrl = localStorage.getItem("initialUrl") as string;
    }
  }

  resetInitalUrl(){
    this.initialUrl=""
  }

  getInitialUrl(): string {
    return this.initialUrl;
  }
}

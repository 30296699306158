import { AncillaryServices } from "./order-model";
import { AncillaryService } from "./product-model";

export class CaseDetailsState {
  caseDetails: CaseDetails | null;
  activities: Activity[];
  comments: Comments[];
  constructor(){
    this.caseDetails = null;
    this.activities = [];
    this.comments = [];
  }
}
export class CaseDetails {
  id: string;
  caseNumber: string;
  createdDate: string;
  orderNumber: string;
  priority: string;
  status: string;
  type: string;
  siteContactName: string;
  siteContactPhone: string;
  siteAddress: string;
  productType: string;
  caseType: string;
  cutOffTimeZone: string;
  contact: Contact;
  caseSubType: string;
  placementNotes: string;
  dueDate: string;
  productInfo: ProductDataInfo[];
  attachments: Document[];
  subject: string;
  description: string;
  constructor(){
    this.id = '';
    this.caseNumber = '';
    this.createdDate = '';
    this.orderNumber = '';
    this.priority = '';
    this.status = '';
    this.type = '';
    this.siteContactName = '';
    this.siteContactPhone = '';
    this.siteAddress = '';
    this.productType = '';
    this.caseType = '';
    this.cutOffTimeZone = '';
    this.contact = new Contact();
    this.caseSubType = '';
    this.placementNotes = '';
    this.dueDate = '';
    this.productInfo = [];
    this.attachments = [];
    this.subject = '';
    this.description = '';
  }
}

export class Contact {
  email: string;
  fullName: string;
}

export class Activity {
  dateTime: string;
  activity: string;
}

export class Comments {
  id: string;
  commentType: string;
  lastModifiedDate: string;
  commentBody: string;
  contactId: string;
  commentedByContact: CommentContact;
  commentedByName: string;
  showMore: boolean;
  attachments:Document[];
  constructor(){
    this.commentBody = '';
    this.id = '';
    this.contactId = '';
    this.commentType = '';
    this.lastModifiedDate = '';
    this.commentedByContact = new CommentContact();
    this.commentedByName = '';
    this.attachments = [];
  }
}

export class CommentContact {
  contactId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  id: string;
  constructor(){
    this.firstName = '';
    this.lastName = '';
    this.fullName = '';
    this.contactId = '';
    this.id = '';
  }
}

export class CaseModel {
  accessNotes: string;
  accountId: string;
  caseRegion: string;
  caseSubType: string;
  contactId: string;
  productInfo?: string;
  description: string;
  summary?: string;
  selectedDate: string;
  placementNote: string;
  siteAddress: string;
  siteCityName: string;
  siteContactName: string;
  siteContactPhone: string;
  siteStateName: string;
  orderName: string;
  zipcode: string;
  unitNumbers: string[];
  myussCaseType: string;
  subject:string;
  constructor(){
    this.unitNumbers = [];
  }
}

export class CaseProductInfo {
  Product: string|undefined;
  Size: string;
  Quantity: number;
  "Service Frequency": string | undefined;
  AncillaryServices?: AncillaryServices[] | AncillaryService[] | null;
  Price: number;
  Action: string;
  Notes: string;
  constructor() {
    this.AncillaryServices = [];
  }
}
export class FileDetails {
  name: string ;
  size: number;
  type: string;
  status: string;
  loader: boolean;
  file: File;

  constructor(){
    this.name = '';
    this.size = 0;
    this.type = '';
    this.status = '';
    this.loader = false;
    this.file = new File([], '');
  }
}
export class UploadDialogInput {
  id: string;
  type: string;
  constructor(){
    this.id = '';
    this.type = '';
  }
}


export class ProductDataInfo {
  asset: string;
  size: string;
  noOfUnits: number;
  frequency: string;
  value: number;
  requestType: string;
  instruction: string;
}



export class Document {
  docId: string;
  title: string;
  extension?: string;
}



import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Injectable, signal } from "@angular/core";
import { exhaustMap, interval, Observable, startWith, Subject, takeUntil } from "rxjs";
import { ApiRespDTO } from "src/app/models/api.model";

@Injectable({
  providedIn: "root",
})
export class HelperFunctionService {
  isFooter = signal<boolean>(false);
  constructor(private breakpointObserver: BreakpointObserver) {}

  space(event: any) {
    // console.log(event.target.selectionStart, event.code);
    if (
      ["Space", "Enter"].includes(event.code) &&
      event.target.selectionStart == 0
    ) {
      event.preventDefault();
    }
  }

   toCamelCase(str: string): string {
    const words = str?.split(' ');
    const camelCasedRest = words
      ?.map((word, index) => {
        return index === 0
          ? word.toLowerCase() 
          : word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join('');

    return camelCasedRest;
  }

  convertStringToObject(inputString: string): Record<string, boolean> {
    const keysArray = inputString?.split(';');
    return keysArray?.reduce((acc: Record<string, boolean>, key: string) => {
      const trimmedKey = key.trim();
      const camelCaseKey = this.toCamelCase(trimmedKey);
      acc[camelCaseKey] = true;
      return acc;
    }, {});
  }

  getMatchingTrueFields(userModules:Record<string,boolean>, accountModules:Record<string,boolean>) {
    if(!userModules || !accountModules) return {};
    let enabledModules:Record<string,boolean> = {};
  
    for (let module in userModules) {
      if (userModules.hasOwnProperty(module) && userModules[module] === true && accountModules[module] === true) {
        enabledModules[module] = true;
      }
    }
  
    return enabledModules;
  }


   // General method to detect if the viewport matches the custom media query
   isViewportMatch(query: string): Observable<BreakpointState> {
    return this.breakpointObserver.observe([query]);
  }
  generateUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  startInterval(
    apiCall: () => Observable<ApiRespDTO | null>,
    intervalTime: number,
    stop$: Subject<void>
  ): Observable<ApiRespDTO | null> {
    return interval(intervalTime).pipe(
      startWith(0),
      takeUntil(stop$),
      exhaustMap(() => apiCall())
    );
  }

}
import { Component } from '@angular/core';
import { ConfigService } from '../../services/config/config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-help',
  templateUrl: './user-help.component.html',
  styleUrls: ['./user-help.component.scss']
})
export class UserHelpComponent {

  helpDocsEnabled:boolean = false;
  technicalSupportEnabled: boolean = false;
  billingSupportEnabled: boolean = false
 

constructor(
  private configService: ConfigService,
  private router: Router

){
  this.helpDocsEnabled = this.configService.getConfigProperty('HELP_DOCS_ENABLED');
  this.technicalSupportEnabled = this.configService.getConfigProperty('TECHNICAL_SUPPORT_ENABLED');
  this.billingSupportEnabled = this.configService.getConfigProperty('BILLING_SUPPORT_ENABLED');
}

  openLinkInNewTab(){
    window.open(this.configService.getConfigProperty('HELP_DOCS_URL'),'_blank');
  }

  onTechnicalSupport(){
    this.router.navigateByUrl('/support/technical-support');
  }

  
  onBillingSupport(){
    this.router.navigateByUrl('/support/billing-support');
  }

}

import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";

@Injectable()
export class AccountIDInterceptor implements HttpInterceptor {
  constructor(private profileService: ProfileService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!this.isLastSegmentInArray(request.url, ["me", "signup"]) && this.profileService.selectedAccount().accountId) {
      let params = request.params;
      // Add new parameters
      params = params.set(
        "accountId",
        `${this.profileService.selectedAccount().accountId}`
      );
      // Clone the request and update the parameters
      const paramReq = request.clone({ params });
      return next.handle(paramReq);
    } else {
      return next.handle(request);
    }
  }
  isLastSegmentInArray(url: string, array: string[]): boolean{
    const urlSegments = url.split("/");
    const lastSegment = urlSegments[urlSegments.length - 1];


    return array.includes(lastSegment);
  }
}
